import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { addCulture, deleteCulture } from 'services/API/Cooperative';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DialogContentText } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { displayErrorMessage } from 'utils/tools_functions';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { resetEffects } from 'services/API/Exploitation';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';
import { StyledTooltip, resetErrorsForm } from './CustomGenericDataGridUtils';

const useDataGridCulturesActions = (
  user,
  rows,
  refreshData,
  cultures,
  errorsFormDefault,
  errorsFormCoProduct,
  errorsFormEconomicValue,
  errorsFormCapCategory,
  fromCooperative,
  fromExploitation,
  exploit
) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const [actionId, setActionId] = useState(null);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteEffectsDialog, setOpenDeleteEffectsDialog] = useState(false);

  const handleSaveClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      if (Object.keys(prevRowModesModel).length === 0) {
        return { [id]: { mode: GridRowModes.Edit } };
      } else {
        let resetPrevRowModesModel = {};
        for (const key in prevRowModesModel) {
          if (prevRowModesModel.hasOwnProperty(key)) {
            resetPrevRowModesModel[key] = { mode: GridRowModes.View };
          }
        }
        return { ...resetPrevRowModesModel, [id]: { mode: GridRowModes.Edit, fromAction: true } };
      }
    });
  };

  const handleCancelClick = (id) => (field) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    resetErrorsForm(
      errorsFormDefault,
      errorsFormEconomicValue,
      errorsFormCoProduct,
      errorsFormCapCategory
    );
  };

  const handleDuplicateDialogOpen = (id) => {
    setActionId(id);
    setOpenDuplicateDialog(true);
  };

  const handleDeleteDialogOpen = (id) => {
    setActionId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteEffectsDialogOpen = (id) => {
    setActionId(id);
    setOpenDeleteEffectsDialog(true);
  };

  const getActions = ({ id }) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      return [
        <>
          <StyledTooltip placement="top" title="Enregistrer">
            <GridActionsCellItem
              icon={<SaveIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Enregistrer"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />
          </StyledTooltip>
          <StyledTooltip placement="top" title="Annuler">
            <GridActionsCellItem
              icon={<CancelIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Annuler"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          </StyledTooltip>
        </>,
      ];
    }
    return [
      <>
        <StyledTooltip placement="top" title="Edition">
          <GridActionsCellItem
            icon={<EditIcon sx={{ paddingLeft: '2px !important' }} />}
            label="Edition"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        </StyledTooltip>
        {fromCooperative && (
          <>
            <StyledTooltip placement="top" title="Dupliquer">
              <GridActionsCellItem
                icon={<ContentCopyIcon sx={{ paddingLeft: '2px !important' }} />}
                label="Dupliquer"
                onClick={() => handleDuplicateDialogOpen(id)}
                color="inherit"
              />
            </StyledTooltip>
            <StyledTooltip placement="top" title="Supprimer">
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ paddingLeft: '2px !important' }} />}
                label="Supprimer"
                onClick={() => handleDeleteDialogOpen(id)}
                color="inherit"
              />
            </StyledTooltip>
          </>
        )}
        {fromExploitation && (
          <StyledTooltip placement="top" title="Supprimer les effets">
            <GridActionsCellItem
              icon={<RestartAltIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Supprimer les effets"
              onClick={() => handleDeleteEffectsDialogOpen(id)}
              color="inherit"
            />
          </StyledTooltip>
        )}
      </>,
    ];
  };

  const handleDeleteClick = async () => {
    setOpenDeleteDialog(false);
    const cultureToDelete = rows.find((row) => row.id === actionId);
    try {
      await deleteCulture(cultureToDelete);
      toast.success(`${cultureToDelete.name} supprimé`);
      refreshData();
    } catch (error) {
      toast.error(displayErrorMessage('ERR_DELETE_CULTURE'));
    }
  };

  const renderDeleteDialog = () => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="xs" open={openDeleteDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent dividers>{`Voulez-vous vraiment supprimer cette culture ?`}</DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClick}>
              Oui <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  const handleDeleteEffectsClick = async () => {
    setOpenDeleteEffectsDialog(false);
    const cultureEffectsToDelete = rows.find((row) => row.id === actionId);
    try {
      await resetEffects(exploit, cultureEffectsToDelete);
      toast.success(`Effets de la culture ${cultureEffectsToDelete.name} supprimés`);
      refreshData();
    } catch (error) {
      toast.error(displayErrorMessage('ERR_REINIT_EFFECT'));
    }
  };

  const renderDeleteEffectsDialog = () => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="m" open={openDeleteEffectsDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent
            dividers
          >{`Voulez-vous vraiment supprimer les effets de cette culture ?`}</DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteEffectsClick}>
              Oui <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteEffectsDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  const handleDuplicateClick = async () => {
    setOpenDuplicateDialog(false);
    const cultureToDuplicate = cultures.find((culture) => culture.id === actionId);

    // Key InputCost to delete before sending culture to the back because back will not process it
    // But we need it in front for some display values
    delete cultureToDuplicate?.economicAttributeCollection?.inputCost
    
    cultureToDuplicate.cropCopy = actionId;
    cultureToDuplicate.capCategory = cultureToDuplicate?.capCategory
      ? cultureToDuplicate?.capCategory?.id
      : null;
    cultureToDuplicate.constraint = cultureToDuplicate?.constraint
      ? cultureToDuplicate?.constraint?.id
      : null;
    cultureToDuplicate.name = `${cultureToDuplicate.name} copie`;
    const { id, rotationEffectN1s, rotationEffectN2s, groundTypeEffects, ...body } =
      cultureToDuplicate;
    try {
      let cooperativeId = -1;
      if (user?.role === 'SUPER_ADMIN') {
        cooperativeId = Number(localStorage.getItem('cooperative'));
      }
      await addCulture(body, cooperativeId);
      toast.success(`${cultureToDuplicate.name} créée`);
      refreshData();
    } catch (error) {
      toast.error(displayErrorMessage('ERR_ADD_CULTURE'));
    }
  };

  const renderDuplicateDialog = () => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="xs" open={openDuplicateDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent dividers>
            {`Voulez-vous vraiment dupliquer cette culture ?`}
            <DialogContentText>
              <InfoIcon />
              {`Cela implique une récupération de tous les effets de la culture ainsi que les données agro-économiques`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDuplicateClick}>
              Oui <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDuplicateDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  return {
    rowModesModel,
    setRowModesModel,
    getActions,
    renderDeleteDialog,
    renderDuplicateDialog,
    renderDeleteEffectsDialog,
  };
};

export default useDataGridCulturesActions;
