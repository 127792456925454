import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import { useCallback, useState } from 'react';
import { parcelSynthesisTheme } from '../../../../utils/Datagrid/CustomForecastDataGridUtils';
import { checkObjectsEquality } from 'utils/tools_functions';
import { CustomSimulationCell, CustomEditSelectCell } from './CustomSimulationDataGrid';

const DataGridParcelSynthesis = ({
  rows,
  columns,
  cultures,
  setOther,
  setPac,
  setPacHelps,
  defaultOtherTable,
  irrigationInformation,
  setHasChange,
  suggest,
  setSuggest,
  editedSynthesisCellsRef,
}) => {
  const [selectIndex, setSelectIndex] = useState(null);

  const renderSelectEditCell = (params) => {
    return (
      <CustomEditSelectCell params={params} culturesList={cultures} selectIndex={selectIndex} />
    );
  };

  const processedColumns = columns?.map((column, index) => {
    let processedColumn = {
      field: column,
      headerName: column,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: () => {
        if (index === 2) {
          return 'MuiDataGrid-colCellSynthesis';
        }
      },
    };

    if (column === 'Parcelle') {
      processedColumn = {
        ...processedColumn,
        cellClassName: 'MuiDataGrid-colCellSynthesis-parcelle',
        renderCell: (params) => {
          const row = rows?.find((row) => row.id === params.id);
          return (
            <p>
              {params.value} {row?.isParent && <span>*</span>}
            </p>
          );
        },
      };
    }

    if (index === 0 || index === 2) {
      processedColumn.minWidth = 170;
      processedColumn.maxWidth = 170;
    }
    if (index === 1) {
      processedColumn.minWidth = 110;
      processedColumn.maxWidth = 110;
      processedColumn.valueFormatter = (value) => {
        if (typeof value === 'number') {
          return Number(value.toFixed(2));
        }
        return value;
      };
    }

    if (index >= 3) {
      processedColumn = {
        ...processedColumn,
        index: index - 3,
        editable: true,
        minWidth: 180,
        renderCell: (params) =>
          CustomSimulationCell(params, editedSynthesisCellsRef, setSelectIndex),
        renderEditCell: renderSelectEditCell,
        cellClassName: (params) => {
          return `${params.row.isParcelDivision}` ? 'MuiDataGrid-colCellSynthesis-division' : '';
        },
      };

      return processedColumn;
    }

    return processedColumn;
  });

  const processedRows = rows?.map((row, index) => {
    let processedRow = {
      id: row?.id,
    };

    irrigationInformation?.map((year) => {
      if (year?.irrigationDistribution?.[index] > 0) {
        processedRow = {
          ...processedRow,
          isIrrigated: true,
        };
      }
    });

    processedColumns?.forEach((column, idx) => {
      switch (column.field) {
        case 'Parcelle':
          processedRow[column?.field] = row?.name;
          break;
        case 'Surface':
          processedRow[column?.field] = row?.surface;
          break;
        default:
          if (idx === 2) {
            processedRow[column?.field] = row?.cultureN;
          }
          if (idx > 2) {
            const cultureByIndex = row?.cultures[idx - 3];
            const isParcelDivision = Array.isArray(cultureByIndex) && cultureByIndex?.length > 1;
            processedRow[column?.field] = {
              isParcelDivision,
              cultures: cultureByIndex ? cultureByIndex : [],
            };
          }
      }
    });
    return processedRow;
  });

  const processRowUpdate = useCallback(
    (updatedRow, previousRow) => {
      if (!checkObjectsEquality(updatedRow, previousRow)) {
        const simulatedCultures = Object.entries(updatedRow).filter(
          ([_, value]) => typeof value === 'object'
        );

        const simulatedCulturesObject = Object.fromEntries(simulatedCultures);

        const simulatedCulturesArray = Object.entries(simulatedCulturesObject).map(
          (entry) => entry[1].cultures
        );

        const flattenedCulturesArray = simulatedCulturesArray.flat();

        const editedCell = flattenedCulturesArray.find(
          (editedCulture) => editedCulture?.isEdited === true
        );

        if (!editedSynthesisCellsRef.current.includes(editedCell?.cellId)) {
          editedSynthesisCellsRef.current.push(editedCell?.cellId);
        }

        const updatedSuggest = { ...suggest };

        let simulatedCulturesArrayClone = structuredClone(simulatedCulturesArray);

        const foundParcel = updatedSuggest.parcels.find((parcel) => parcel.id === updatedRow.id);

        simulatedCulturesArrayClone.forEach((cultures) => {
          cultures.forEach((culture) => {
            if (culture.cellId) {
              delete culture.cellId;
            }
            if (culture.isEdited) {
              delete culture.isEdited;
            }
          });
        });

        foundParcel.cultures = simulatedCulturesArrayClone;

        setSuggest(updatedSuggest);
        setOther(JSON.parse(JSON.stringify(defaultOtherTable)));
        setPac([]);
        setPacHelps([]);
        setHasChange(true);

        return updatedRow;
      }

      return previousRow;
    },
    [rows]
  );

  const getRowClassName = () => 'Mui-even';

  return (
    <div className="datagrid_container">
      <CustomDataGridResult
        rows={processedRows}
        columns={processedColumns}
        getRowClassName={getRowClassName}
        processRowUpdate={processRowUpdate}
        theme={parcelSynthesisTheme}
        simulationContext={'parcel-synthesis-box'}
        fullSize={true}
        fromSimulation
      />
    </div>
  );
};

export default DataGridParcelSynthesis;
