import { useContext } from 'react';
import { PdfContext } from 'utils/context';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';
import ParcelSynthesisTable from '../Tables/ParcelSynthesisTable';

const ParcelSynthesis = () => {
  const { suggest } = useContext(PdfContext);
  const numberOfRowPerTable = 7;
  let toDuplicate = true;
  let repetition = 1;
  let slicedParcelSynthesisTable = [];

  while (toDuplicate) {
    // On découpe les tableaux en 8 éléments pour qu'ils soit lisible
    let truncatedResult = truncateTab(suggest?.parcels, numberOfRowPerTable, repetition);
    slicedParcelSynthesisTable.push(
      <ParcelSynthesisTable
        key={repetition}
        repetition={repetition}
        parcels={truncatedResult}
        years={suggest?.years}
        messageDuplicate={repetition > 1 ? '(Suite)' : ''}
      />
    );
    lengthInInterval(suggest?.parcels, repetition, numberOfRowPerTable)
      ? repetition++
      : (toDuplicate = false);
  }

  return slicedParcelSynthesisTable;
};

export default ParcelSynthesis;
