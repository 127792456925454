import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import pdfIcon from 'assets/images/pdf-16.png';
import { default as Button, default as IconButton } from 'components/generics/Button';
import Checkbox from 'components/generics/Checkbox';
import Loader from 'components/generics/Loader';
import MapResults from 'components/generics/Map/MapResults';
import ResultsInfo from 'components/generics/Map/ResultsInfo';
import { FormModal } from 'components/generics/Modals/FormModal';
import { ReportModal } from 'components/generics/Modals/reportModal';
import PdfDocument from './PDF/PdfDocument';
import SimulationCard from 'components/pages/Exploitation/Simulation/SimulationCard';
import useAuth from 'providers/Auth/useAuth';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllTechnicianFull, getCooperative } from 'services/API/Cooperative';
import { getParcelsByExploitation } from 'services/API/Exploitation';
import {
  getSimulationResult,
  getSimulationStatus,
  launch_calculate,
  sendReport,
  simulate,
} from 'services/API/Simulation';
import { sendEvent } from 'utils/Event';
import { ROLE_ADMIN, ROLE_FARMER, ROLE_TECHNICIAN } from 'utils/const';
import { ExploitationContext, LoadingContext, ModalContext, NavContext } from 'utils/context';
import { culturesColorsArray, getColorById, getColorByName } from 'utils/culturesColors';
import { formatNumberWithSpaces, minimalRoleRequired } from 'utils/index';
import { displayErrorMessage, displayMessage } from 'utils/tools_functions';
import { v1 } from 'uuid';
import { SimulationContext } from '../SimulationContext';
import { RenderConstraintNotRespected } from './RenderConstraintNotRespected';
import './result.style.scss';
import DataGridCAPPoints from './DataGridCAPPoints';
import DataGridCAPHelp from './DataGridCAPHelp';
import DataGridKeyNumber from './DataGridKeyNumber';
import DataGridParcelSynthesis from './DataGridParcelSynthesis';
import MapTab from 'components/generics/Map/MapTab';
import logoCover from 'assets/images/logo-cover-min.png';
import DataGridKeyNumberNitrogen from './DataGridKeyNumberNitrogen';

const DEFAULT_LINE_CHART = [{ id: 'Marge brute (€)', data: [] }];

const DEFAULT_OTHER_TABLE = [
  { id: 'Marge brute (€)', data: [] },
  { id: '% culture été (%)', data: [] },
];

const Result = ({ exploitationSelected, setExploitation }) => {
  const { culturesColors } = useContext(ExploitationContext);
  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const [result, setResult] = useState(null);
  const [exploitationParcels, setExploitationParcels] = useState([]);
  const [suggest, setSuggest] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  const [colorList, setColorList] = useState(culturesColorsArray);
  const [selectedYear, setSelectedYear] = useState(1);
  const [lineGraphData, setLineGraphData] = useState(DEFAULT_LINE_CHART);
  const [loading, setLoading] = useState(true);
  const [other, setOther] = useState(JSON.parse(JSON.stringify(DEFAULT_OTHER_TABLE)));
  const [otherNitrogen, setOtherNitrogen] = useState([]);
  const [pac, setPac] = useState([]);
  const [pacHelps, setPacHelps] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMap, setModalMap] = useState(false);
  const [coordsExist, setCoordsExist] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [selectedProposals, setSelectedProposals] = useState([]);
  const [currentSector, setCurrentSector] = useState('');
  const { user } = useAuth();
  const { limit, setLimit } = React.useContext(SimulationContext);
  const { cultures, setCultures } = React.useContext(SimulationContext);
  const { selectedConstraints } = React.useContext(SimulationContext);

  const [hasChange, setHasChange] = useState(false);
  const [currentCooperative, setCurrentCooperative] = useState(null);
  const [jobId, setJobId] = useState('');
  const [techniciansAddressMailList, setTechniciansAddressMailList] = useState([]);
  const [listMailUserFromModal, setListMailUserFromModal] = useState([]);
  const [confirmSendReport, setConfirmSendReport] = useState(false);
  const [exploitationContext, setExploitationContext] = useState(exploitationSelected);
  const { resetDataLoader, setCustomText, setForceLoader } = useContext(LoadingContext);
  const [yScale, setYScale] = useState({
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER,
  });
  const [mapTab, setMap] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showModal, updateShowModal] = useState(false);
  const [mapYear, setMapYear] = useState(null);
  const currentYear = +localStorage.getItem('currentYear');
  const navigate = useNavigate();
  const editedSynthesisCellsRef = useRef([]);
  const pieChartContainerRef = useRef();
  const [pieChartContainerHeight, setPieChartContainerHeight] = useState(0);
  const isNitrogenASelectedConstraint =
    selectedConstraints[selectedConstraints.findIndex((x) => x.nitrogen)]?.nitrogen;
  const isCoverProposalASelectedConstraint =
    selectedConstraints[selectedConstraints.findIndex((x) => x.nitrogen)]?.nitrogen?.coverProposal;
  const isTFIHerbicideASelectedConstraint = !!selectedConstraints.find(
    (x) => x.name === 'TFIHerbicideConstraint'
  );
  const isTFIWithoutHerbicideASelectedConstraint = !!selectedConstraints.find(
    (x) => x.name === 'TFIWithoutHerbicideConstraint'
  );
  const labelNitrogenCoverGainInUnit = `Gain d'azote lié aux couverts à base légumineuses`;
  const labelNitrogenCoverGainInCurrency = `Gain lié à l'azote du couvert à base légumineuses`;
  const labelNitrogenCropGain = `Gain d'azote lié aux légumineuses`;
  const labelNitrogenQuantity = `Unité d'azote`;
  const labelNitrogenCapital = `Capital azote apporté dans l'année au bénéfice des années suivantes`;

  const toggleModal = () => {
    if (!showModal) {
      let options = null;
      if (minimalRoleRequired(user.role, ROLE_ADMIN)) {
        options = {
          queryParamsString: { cooperative: localStorage.getItem('cooperative') },
        };
      }
      getAllTechnicianFull(options)
        .then((res) => {
          const users = res.data.data;
          const techniciansSector = [];

          for (const user of users) {
            if (user.roles.includes('ROLE_TECHNICIAN')) {
              const technicianSector = user.sectors.filter(
                (sector) => sector?.id === +location.state.exploitationSelected.sector.id
              );
              if (technicianSector.length > 0) {
                techniciansSector.push(user.email);
              }
            }
          }
          setTechniciansAddressMailList([...techniciansSector]);
          updateShowModal(!showModal);
        })
        .catch(() => {
          toast.error(displayErrorMessage('ERR_GET_TECHNICIAN_FULL'));
        });
    } else {
      updateShowModal(!showModal);
    }
  };

  useEffect(() => {
    if (confirmSendReport) {
      sendPdfReportMail();
      setConfirmSendReport(!confirmSendReport);
    }
  }, [confirmSendReport]);

  useEffect(() => {
    if (suggest) {
      const graphLines = JSON.parse(JSON.stringify(DEFAULT_LINE_CHART));
      suggest.years.map((e, i) => {
        graphLines
          .filter((elem) => elem.id === 'Marge brute (€)')[0]
          .data.push({
            y: e.grossMargin,
            x: `Campagne ${currentYear + i}-${currentYear + i + 1}`,
          });
        return true;
      });

      let minY = yScale.min;
      let maxY = yScale.max;

      // loop all data of array b for search the smallest and biggest value, and update minY / maxY
      graphLines.forEach((item) => {
        item.data.forEach((data) => {
          minY = Math.min(minY, data.y);
          maxY = Math.max(maxY, data.y);
        });
      });
      setYScale({
        min: minY,
        max: maxY,
      });

      setLineGraphData(graphLines);
    }
  }, [suggest]);

  useEffect(() => {
    if (pieChartContainerRef?.current && !loading) {
      setPieChartContainerHeight(pieChartContainerRef?.current?.offsetHeight);
    }
  }, [pieChartContainerRef, loading]);

  useEffect(() => {
    sendEvent('loaderFullScreen', { loaderNeeded: false });
    if (!exploitationContext?.exploitationSelected) {
      setExploitation(+localStorage.getItem('exploitation'))
        .then((resp) => setExploitationContext(resp.data.data))
        .catch((err) => toast.error(err.data));
    }

    if (limit.length === 0) {
      const temp = location.state.selectedCultures.map((culture) => ({
        id: culture.id,
        min: 0,
        max: 0,
        minPct: 0,
        maxPct: 0,
      }));
      setLimit(temp);
    }
    const selectedCultures = [...location.state.selectedCultures];
    const selectedCulturesWithMinMax = selectedCultures.map((culture, index) => {
      let max = 0;
      let min = 0;
      let type = 'undefined';
      if (location.state.maxs[index] !== 0 || location.state.mins[index] !== 0) {
        max = location.state.maxs[index];
        min = location.state.mins[index];
        type = 'ha';
      } else if (location.state.maxsPct[index] !== 0 || location.state.minsPct[index] !== 0) {
        max = location.state.maxsPct[index];
        min = location.state.minsPct[index];
        type = '%';
      }

      return { ...culture, constraint: { min: min, max: max, type: type } };
    });
    setCultures(selectedCulturesWithMinMax);
    const coopId = localStorage.getItem('cooperative')
      ? localStorage.getItem('cooperative')
      : user.cooperative.id;
    getCooperative(coopId)
      .then((resp) => {
        setCurrentCooperative(resp.data.data);
      })
      .catch((err) => toast.error(err.data));

    setCurrentSector(location.state.exploitationSelected.sector.name);
    simulate(location.state.exploitationSelected.id, {
      parcels: extractId(location.state.selectedParcels),
      mins: location.state.mins,
      maxs: location.state.maxs,
      minsPct: location.state.minsPct,
      maxsPct: location.state.maxsPct,
      cultures: extractId(location.state.selectedCultures),
      constraints: location.state.selectedConstraints,
      objectives: formatItem(location.state.selectedObjectives, {
        value: 100,
      }),
      yearsToSimulate: location.state.nbYears,
      obligationRestriction: location.state.obligationAndRestriction,
    })
      .then((e) => {
        const jobId = e.data.data.job_id;
        setJobId(jobId);
        retrieveJob(jobId);
      })
      .catch((err) => {
        if (err?.response) {
          toast.error(err.response.data.message, { autoClose: false });
        } else {
          toast.error(displayErrorMessage('ERR_UNEXPECTED'), { autoClose: false });
        }
        navigate('/exploitation/simulation');
      });

    getParcelsByExploitation(location.state.exploitationSelected?.id)
      .then((e) => {
        setExploitationParcels(e.data.data);
        setCoordsExist(e.data.data.find((p) => p.coordinates?.length > 0));
      })
      .catch(() => toast.error(displayErrorMessage('ERR_GET_PARCEL')));
  }, []);

  const buildPreviousPacData = (pacPointsList) => {
    previousData?.capPoints?.map((arrayCapPoints, index) => {
      let total = 0;
      arrayCapPoints.map((capPoint, i) => {
        pacPointsList[i].data.push({
          value: capPoint,
          x: `Année ${previousData?.capPoints.length > 1 ? index - 1 : index}`,
        });
        total += capPoint;
      });
      pacPointsList.filter((elt) => elt.categorie === 'Total')[0].data.push({ value: total });
    });

    return pacPointsList;
  };

  const buildPreviousData = () => {
    let orderedPreviousData = [];
    const constraintsList = { ...previousData?.constraintValues };
    const nitrogenList = [{ ...previousData?.nitrogen }];

    for (const [key, value] of Object.entries(constraintsList)) {
      previousData[key] = value;
    }
    for (const [key, value] of Object.entries(nitrogenList)) {
      previousData[key] = value;
    }
    const { constraintValues, ...restObject } = previousData;

    for (let i = 0; i < previousData?.capPoints?.length; i++) {
      for (const [key, value] of Object.entries(restObject)) {
        orderedPreviousData[i] = { ...orderedPreviousData[i], [key]: value[i] };
        nitrogenList[i] = { ...nitrogenList[i], [key]: value[i] };
      }
    }

    orderedPreviousData.map((e, i) => {
      other
        .filter((elem) => elem.id === 'Marge brute (€)')[0]
        .data.push({
          value: e.grossMargin ? `${formatNumberWithSpaces(e.grossMargin)} €` : '-',
          x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
        });
      other
        .filter((elem) => elem.id === '% culture été (%)')[0]
        .data.push({
          value: e.propCultureEte ? `${e.propCultureEte} %` : '-',
          x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
        });
      result.constraints.typeCoProduit.forEach((constraint, index) =>
        other
          .filter((elem) => elem.id === `Quantité ${constraint.displayName}`)[0]
          .data.push({
            value: previousData?.constraintValues?.qttTypeCoProduit[i][index]
              ? `${previousData?.constraintValues?.qttTypeCoProduit[i][index]} t`
              : '-',
            x: `Année ${i - 1} `,
          })
      );
      if (isTFIHerbicideASelectedConstraint) {
        other
          .filter((tfi) => tfi.id === `IFT moyen herbicides`)[0]
          .data.push({
            value: e.averageTFIHerbicide ? `${e.averageTFIHerbicide}` : '-',
            x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
          });
      }
      if (isTFIWithoutHerbicideASelectedConstraint) {
        other
          .filter((tfi) => tfi.id === `IFT moyen hors herbicides`)[0]
          .data.push({
            value: e.averageTFIWithoutHerbicide ? `${e.averageTFIWithoutHerbicide}` : '-',
            x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
          });
      }
    });

    if (isNitrogenASelectedConstraint) {
      nitrogenList.map((e, i) => {
        if (e.quantity >= 0 && e.cropGain >= 0) {
          otherNitrogen
            .filter((nitrogen) => nitrogen.id === labelNitrogenQuantity)[0]
            .data.push({
              value: e?.quantity >= 0 ? `${formatNumberWithSpaces(e.quantity)} u` : '-',
              x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
            });

          otherNitrogen
            .filter((elem) => elem.id === labelNitrogenCapital)[0]
            .data.push({
              value:
                e.nitrogenCapital >= 0 ? `${formatNumberWithSpaces(e.nitrogenCapital)} €` : '-',
              x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
            });

          otherNitrogen
            .filter((nitrogen) => nitrogen?.id === labelNitrogenCropGain)[0]
            .data.push({
              value: e?.cropGain >= 0 ? `${formatNumberWithSpaces(e.cropGain)} u` : '-',
              x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
            });
        }

        if (isCoverProposalASelectedConstraint) {
          otherNitrogen
            .filter((nitrogen) => nitrogen?.id === labelNitrogenCoverGainInUnit)[0]
            .data.push({
              value:
                e?.coverGainInUnit >= 0 ? `${formatNumberWithSpaces(e.coverGainInUnit)} u` : '-',
              x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
            });

          otherNitrogen
            .filter((nitrogen) => nitrogen?.id === labelNitrogenCoverGainInCurrency)[0]
            .data.push({
              value:
                e?.coverGainInCurrency >= 0
                  ? `${formatNumberWithSpaces(e.coverGainInCurrency)} €`
                  : '-',
              x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
            });
        }
        const coverGainByCultures = e.gainPerCropPerHectareList.map(
          (coverGainByCulture, index) => ({
            culture: result.cultures[index]?.name,
            value: coverGainByCulture,
          })
        );

        coverGainByCultures.forEach((coverGainByCulture) => {
          if (!otherNitrogen.find((x) => x.id === coverGainByCulture.culture)) {
            otherNitrogen.push({
              id: coverGainByCulture.culture,
              isChild: true,
              data: [
                {
                  value:
                    coverGainByCulture.value > 0
                      ? `${formatNumberWithSpaces(coverGainByCulture.value)} u/ha`
                      : '-',
                  x: `Année ${previousData?.capPoints?.length > 1 ? i - 1 : i}`,
                },
              ],
            });
          }
        });
      });
    }
  };

  useEffect(() => {
    if (suggest) {
      result.constraints.typeCoProduit.forEach((constraint) =>
        other.push({
          id: `Quantité ${constraint.displayName}`,
          data: [],
        })
      );
      if (isTFIHerbicideASelectedConstraint) {
        other.push({
          id: `IFT moyen herbicides`,
          data: [],
        });
      }
      if (isTFIWithoutHerbicideASelectedConstraint) {
        other.push({
          id: `IFT moyen hors herbicides`,
          data: [],
        });
      }

      if (suggest.years?.[0].nitrogen && otherNitrogen.length === 0) {
        otherNitrogen.push({
          id: labelNitrogenCapital,
          data: [],
        });
        otherNitrogen.push({
          id: labelNitrogenQuantity,
          data: [],
        });
        otherNitrogen.push({
          id: labelNitrogenCropGain,
          data: [],
          isParent: true,
        });
        if (
          isCoverProposalASelectedConstraint &&
          suggest.years?.[0].nitrogen?.coverGainInUnit >= 0
        ) {
          otherNitrogen.push({
            id: labelNitrogenCoverGainInUnit,
            data: [],
            unit: `${currentCooperative?.nitrogenSupplyByCover} u/ha`,
          });
          otherNitrogen.push({
            id: labelNitrogenCoverGainInCurrency,
            data: [],
          });
        }
      }

      buildPreviousData();

      suggest.years.map((e, i) => {
        other
          .filter((elem) => elem.id === 'Marge brute (€)')[0]
          .data.push({
            value: e.grossMargin ? `${formatNumberWithSpaces(e.grossMargin)} €` : '-',
            x: `Année ${i + 1}`,
          });
        other
          .filter((elem) => elem.id === '% culture été (%)')[0]
          .data.push({
            value: e.propCultureEte ? `${e.propCultureEte} %` : '-',
            x: `Année ${i + 1}`,
          });
        if (isTFIHerbicideASelectedConstraint) {
          other
            .filter((elem) => elem.id === 'IFT moyen herbicides')[0]
            .data.push({
              value: e.averageTFIHerbicide ? `${e.averageTFIHerbicide}` : '-',
              x: `Année ${i + 1}`,
            });
        }
        if (isTFIWithoutHerbicideASelectedConstraint) {
          other
            .filter((elem) => elem.id === 'IFT moyen hors herbicides')[0]
            .data.push({
              value: e.averageTFIWithoutHerbicide ? `${e.averageTFIWithoutHerbicide}` : '-',
              x: `Année ${i + 1}`,
            });
        }

        if (isNitrogenASelectedConstraint && e.nitrogen) {
          otherNitrogen
            .filter((elem) => elem.id === labelNitrogenQuantity)[0]
            .data.push({
              value:
                e.nitrogen.quantity >= 0 ? `${formatNumberWithSpaces(e.nitrogen.quantity)} u` : '-',
              x: `Année ${i + 1}`,
            });
          otherNitrogen
            .filter((elem) => elem.id === labelNitrogenCropGain)[0]
            .data.push({
              value:
                e.nitrogen.cropGain >= 0 ? `${formatNumberWithSpaces(e.nitrogen.cropGain)} u` : '-',
              x: `Année ${i + 1}`,
            });

          const coverGainByCultures = e.nitrogen.gainPerCropPerHectareList.map(
            (coverGainByCulture, index) => {
              const cultureName = result.cultures[index]?.name;
              return {
                culture: cultureName,
                value: coverGainByCulture,
              };
            }
          );
          coverGainByCultures.forEach((coverGainByCulture) => {
            otherNitrogen
              .filter((elem) => elem.id === coverGainByCulture.culture)[0]
              .data.push({
                value:
                  coverGainByCulture.value > 0
                    ? `${formatNumberWithSpaces(coverGainByCulture.value)} u/ha`
                    : '-',
                x: `Année ${i + 1}`,
              });
          });

          otherNitrogen
            .filter((elem) => elem.id === labelNitrogenCapital)[0]
            .data.push({
              value:
                e.nitrogen.nitrogenCapital >= 0
                  ? `${formatNumberWithSpaces(e.nitrogen.nitrogenCapital)} €`
                  : '-',
              x: `Année ${i + 1}`,
            });

          if (isCoverProposalASelectedConstraint && e?.nitrogen?.coverGainInUnit >= 0) {
            otherNitrogen
              .filter((elem) => elem.id === labelNitrogenCoverGainInUnit)[0]
              .data.push({
                value:
                  e.nitrogen.coverGainInUnit >= 0
                    ? `${formatNumberWithSpaces(e.nitrogen.coverGainInUnit)} u`
                    : '-',
                x: `Année ${i + 1}`,
              });
            otherNitrogen
              .filter((elem) => elem.id === labelNitrogenCoverGainInCurrency)[0]
              .data.push({
                value:
                  e.nitrogen.coverGainInCurrency >= 0
                    ? `${formatNumberWithSpaces(e.nitrogen.coverGainInCurrency)} €`
                    : '-',
                x: `Année ${i + 1}`,
              });
          }
        }

        result.constraints.typeCoProduit.forEach((constraint) =>
          other
            .filter((elem) => elem.id === `Quantité ${constraint.displayName}`)[0]
            .data.push({
              value: e[constraint.displayName] ? `${e[constraint.displayName]} t` : '-',
              x: `Année ${i + 1}`,
            })
        );
        return true;
      });
    }
  }, [suggest]);

  useEffect(() => {
    if (suggest && suggest.years[0].capPoints) {
      if (pac?.length === 0) {
        result.capHelps.map((elt) => pacHelps.push(`${formatNumberWithSpaces(elt)} €`));
        suggest.years[0].capPoints.map((m) => pac.push({ categorie: m.categorie, data: [] }));
        pac.push({ categorie: 'Total', data: [] });
        buildPreviousPacData(pac);
        suggest.years.map((e, i) => {
          let total = 0;
          e.capPoints.map((p) => {
            pac
              .filter((elt) => elt.categorie === p.categorie)[0]
              .data.push({ value: p.points, x: `Année ${i + 1}` });
            total += p.points;
            return [];
          });
          pac.filter((elt) => elt.categorie === 'Total')[0].data.push({ value: total });
          return [];
        });
      }
    }
  }, [suggest]);

  const setColor = () => {
    const color = `#${`${(Math.floor(Math.random() * 56) + 200).toString(16)}${(
      Math.floor(Math.random() * 56) + 200
    ).toString(16)}${(Math.floor(Math.random() * 56) + 200).toString(16)}`}`;
    return color;
  };

  const extractId = (tab) => {
    const idTab = [];
    tab.forEach((item) => idTab.push(item.id));
    return idTab;
  };

  const formatItem = (tab, adding) => {
    const newTab = [];
    tab.forEach((item) => {
      newTab.push({ ...item, ...adding });
    });
    return newTab;
  };

  const extractProposal = (obj) => {
    const idTab = [];
    Object.entries(obj).map(([key]) => idTab.push(key));
    return idTab;
  };

  const toggleProposals = (value) => {
    selectedProposals.indexOf(value) === -1
      ? setSelectedProposals([value])
      : setSelectedProposals(selectedProposals.filter((elem) => elem !== value));
  };

  const buildPdf = async () => {
    const totalNbYear = result?.nbYear + 1;
    setSelectedYear(0);
    for (const p of selectedProposals) {
      const margeBrutChart = await chartToPng('#marge-graph');
      const graphProductionPerYear = [];
      const graph = [];
      for (let i = 0; i < totalNbYear; i++) {
        setSelectedYear((prev) => {
          if (prev + 1 === totalNbYear) {
            return 0;
          }
          return prev + 1;
        });
        const productionGraph = await chartToPng('#graph-production');
        graph.push(productionGraph);
      }
      graphProductionPerYear.push({ proposal: p, charts: graph });
      let results = addCultureNToResult();
      const hasPacConstraint = location?.state?.selectedConstraints?.find(
        (constraint) => constraint?.id === 16
      )?.min;
      if (hasPacConstraint) {
        results = {
          ...results,
          constraints: {
            ...results.constraints,
            pac: {
              displayName: 'Nombre de points PAC à aller chercher',
              min: hasPacConstraint,
              max: hasPacConstraint,
            },
          },
        };
      }
      return await PdfDocument(
        results,
        p,
        location.state.exploitationSelected,
        user,
        currentSector,
        margeBrutChart,
        other,
        otherNitrogen,
        graphProductionPerYear,
        cultures,
        currentCooperative,
        pac,
        pacHelps,
        jobId,
        currentYear,
        location.state.obligationAndRestriction,
        isCoverProposalASelectedConstraint,
        isNitrogenASelectedConstraint
      );
    }
  };

  const sendPdfReportMail = async () => {
    // Force Display Loader
    setForceLoader(true);
    setCustomText(displayMessage('WAIT_BUILD_PDF'));
    const pdfDoc = await buildPdf();

    resetDataLoader();
    // Set FormData to send the data to the api
    let formData = new FormData();
    formData.append('pdf', pdfDoc.pdf.output('blob'), 'simulation.pdf');
    formData.append(
      'pdfName',
      'Assolement_' + pdfDoc.dformat + '_' + location.state.exploitationSelected?.name
    );
    // Add [] after the variable to type the value as an array
    for (const mailUser of listMailUserFromModal) {
      formData.append('mail[]', mailUser);
    }
    formData.append('exploitationID', exploitationSelected.id);
    setCustomText(displayMessage('WAIT_SEND_MAIL'));
    sendReport(formData)
      .then(() => {
        toast.success(displayMessage('SEND_REPORT_MAIL_OK'));
      })
      .catch(() => {
        toast.error(displayErrorMessage('ERR_SEND_REPORT_SIMULATION'));
      });
  };

  const downloadPDF = async () => {
    const pdfDoc = await buildPdf();
    pdfDoc.pdf.save(
      'Assolement_' + pdfDoc.dformat + '_' + location.state.exploitationSelected?.name + '.pdf'
    );
    setModal(false);
  };

  const chartSvgToPng = (svgDataurl) =>
    new Promise((resolve) => {
      let canvas;
      let ctx;
      let img;

      img = new Image();
      img.src = svgDataurl;
      img.onload = () => {
        const { width } = img;
        const { height } = img;
        canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height, 0, 0, width, height);

        img = new Image();
        img.src = canvas.toDataURL('image/png');
        img.onload = () => {
          canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, width, height);
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/jpeg'));
        };
      };
    });

  const constructPacTable = (data) => {
    if (data && data.years[0].capPoints) {
      const pacTable = [];
      data.years[0].capPoints.map((m) => pacTable.push({ categorie: m.categorie, data: [] }));
      pacTable.push({ categorie: 'Total', data: [] });
      buildPreviousPacData(pacTable);
      data.years.map((e, i) => {
        let total = 0;
        e.capPoints.map((p) => {
          pacTable
            .filter((elt) => elt.categorie === p.categorie)[0]
            .data.push({ value: p.points, x: `Année ${i + 1}` });
          total += p.points;
          return [];
        });
        pacTable.filter((elt) => elt.categorie === 'Total')[0].data.push({ value: total });
        return [];
      });
      setPac(pacTable);
    }
  };

  const launchCalculette = () => {
    // construction du tableau d'irrigation d'une culture par parcelle et année
    const repartitionIrrigation = [];
    suggest.parcels.forEach((p, idx) => {
      const irrigationPerParcelAndYears = [];
      suggest.years.forEach((y) => {
        irrigationPerParcelAndYears.push(y.irrigationDistribution[idx]);
      });
      repartitionIrrigation.push(irrigationPerParcelAndYears);
    });

    const body = {
      parcels: suggest.parcels,
      repartitionIrrigation,
    };

    launch_calculate(body, location.state.exploitationSelected?.id)
      .then((res) => {
        editedSynthesisCellsRef.current = [];
        const newResult = res.data.data;
        const objectEntries = Object.entries(result.suggestions);
        objectEntries.forEach((s) => {
          if (s[0] === selectedProposals[0]) {
            s[1] = newResult.result;
          }
        });
        newResult.suggestions = Object.fromEntries(objectEntries);
        newResult.objectives = result.objectives;
        setResult(newResult);
        setOther(JSON.parse(JSON.stringify(DEFAULT_OTHER_TABLE)));
        setOtherNitrogen([]);
        const activeSuggestion = Object.entries(newResult?.suggestions).find(
          ([key, _]) => key === selectedProposals[0]
        )[1];
        setSuggest(activeSuggestion);
        const proposals = extractProposal(newResult.suggestions);
        setProposals(proposals);
        setPacHelps(result.capHelps);
        constructPacTable(activeSuggestion);
        setHasChange(false);
        let errorCount = 0;
        newResult.result.years.map((year) => {
          if (year.errorsConstraints) {
            Object.keys(year.errorsConstraints).map((errorConstraint) => {
              errorCount += Object.keys(year.errorsConstraints[errorConstraint]).length;
            });
          }
        });
        if (errorCount === 0) {
          toast.success('Les Chiffres clés et points PAC ont été mis à jour');
        } else {
          toast.warning(
            `Le nouveau calcul de simulation présente ${errorCount} ${
              errorCount === 1 ? 'erreur' : 'erreurs'
            } de contrainte`
          );
        }
      })
      .catch((error) => {
        const errorToDisplay = error?.response?.data || null;
        if (errorToDisplay?.errors) {
          for (const error of errorToDisplay.errors) {
            toast.error(displayErrorMessage(error.err_code, error.message), {
              autoClose: false,
            });
          }
        }
      });
  };

  const chartToPng = async (elementId, width, height) => {
    const canvas = document.querySelector(elementId);
    const serializedGraph = new XMLSerializer().serializeToString(
      canvas.firstChild.firstChild.firstChild
    );
    const b64Graph = btoa(unescape(encodeURIComponent(serializedGraph)));
    const chart = await chartSvgToPng(`data:image/svg+xml;base64,${b64Graph}`, width, height);
    return chart;
  };

  const changeSuggestion = (suggest, name) => {
    setSuggest(suggest);
    setOther(JSON.parse(JSON.stringify(DEFAULT_OTHER_TABLE)));
    setOtherNitrogen([]);
    setPac([]);
    setPacHelps([]);
    setSelectedProposals([name]);
  };

  const retrieveJob = (jobId) => {
    setTimeout(async () => {
      const status = await getSimulationStatus(jobId);
      if (status.data.data.status !== 'COMPLETE' && status.data.data.status !== 'ERROR') {
        await retrieveJob(jobId);
      } else {
        await getJobResult(jobId);
      }
    }, 2000);
  };

  const getJobResult = async (jobId) => {
    const response = await getSimulationResult(jobId).catch((error) => {
      const errorToDisplay = error?.response?.data || null;
      if (Object.keys(errorToDisplay?.errors).length) {
        for (const error of errorToDisplay.errors) {
          if (error.err_code === 2000) {
            for (const errorInfos of error?.err_info) {
              if (errorInfos.length === 1) {
                errorInfos[0]['message'] = displayErrorMessage(
                  errorInfos[0].error_code,
                  errorInfos[0].additionalInfo
                );
                toast.error(displayErrorMessage(error.err_code + '_ONE', errorInfos[0]), {
                  autoClose: false,
                });
              } else {
                let atinomicConstraintsErrors = [];
                for (const error of errorInfos) {
                  atinomicConstraintsErrors.push(
                    displayErrorMessage(error.error_code, error?.additionalInfo)
                  );
                }
                toast.error(
                  displayErrorMessage(error.err_code + '_SEVERAL', atinomicConstraintsErrors),
                  {
                    autoClose: false,
                  }
                );
              }
            }
          } else {
            toast.error(displayErrorMessage(error.err_code, error.message), {
              autoClose: false,
            });
          }
        }
        sendEvent('loaderFullScreen', { loaderNeeded: true });
        navigate('/exploitation/simulation');
      } else {
        toast.error(displayErrorMessage('ERR_UNEXPECTED'), { autoClose: false });
        showBoundary({ message: displayErrorMessage('ERR_UNEXPECTED'), jobId });
      }
    });
    if (response) {
      const hectaSize =
        response.data.data.suggestions.suggestion1.years[0].hectaresDistribution.length;
      if (hectaSize > 95) {
        const arr = colorList;
        for (let i = 95; i < hectaSize; i++) {
          let newColor;
          do {
            newColor = setColor();
          } while (arr.includes(newColor));
          arr.push(newColor);
        }
        setColorList(arr);
      }
      setResult(response.data.data);
      setPreviousData(response.data.data.previous);
      setSuggest(response.data.data.suggestions.suggestion1);
      const newProposals = extractProposal(response.data.data.suggestions);
      setProposals(newProposals);
      setSelectedProposals([newProposals[0]]);
      setLoading(false);
      sendEvent('loaderFullScreen', { loaderNeeded: true });
    }
  };
  const filterColors = () => {
    return suggest?.years.map((year) =>
      year.hectaresDistribution.map((hectare) => {
        const existingColor = cultures.find((culture) => culture.id === hectare.culture.id)?.color;
        if (existingColor) {
          return getColorByName(
            cultures.find((culture) => culture.id === hectare.culture.id)?.name,
            culturesColors
          );
        }
        return null;
      })
    );
  };
  useEffect(() => {
    filterColors();
  }, [suggest]);

  const addCultureNToResult = () => {
    const results = JSON.parse(JSON.stringify(result));
    for (const key in result.suggestions) {
      results.suggestions[key].parcels.map((item) =>
        item.cultures.unshift(
          exploitationParcels
            ? {
                id: exploitationParcels?.find((parcel) => parcel.id === item.id).cultureN.id,
                name: exploitationParcels?.find((parcel) => parcel.id === item.id).cultureN.name,
              }
            : { id: 0, name: 'pas de culture à afficher' }
        )
      );
    }
    return results;
  };

  function setModalData(parcelToUpdate, parcelYear, minYear) {
    if (parcelYear === minYear) {
      toast.warning(`La campagne en cours n'est pas modifiable`, {
        toastId: 'warning',
      });
    } else {
      if (parcelToUpdate?.isParent) {
        let groupParcelNames = exploitationParcels
          ?.filter((parcel) => parcelToUpdate?.groupChildrenParcels?.includes(parcel?.id))
          ?.map((childrenParcel) => childrenParcel.name);
        parcelToUpdate = { ...parcelToUpdate, groupParcelNames: groupParcelNames };
      }
      setEditData(parcelToUpdate);
      setModalMap(true);
    }
  }

  const getMapParcelsData = () => {
    const parcels = suggest?.parcels.map((selectedParcel) => parcelContextData(selectedParcel));

    const childrenParcels = exploitationParcels
      ?.filter(
        (parcel) =>
          parcel.hasOwnProperty('parent') &&
          suggest?.parcels?.some((parcelle) => parcelle?.id === parcel?.parent) &&
          !suggest?.parcels?.some((parcelle) => parcelle?.id === parcel?.id)
      )
      .map((childrenParcel) => parcelContextData(childrenParcel, true));

    return [...(parcels ?? []), ...(childrenParcels ?? [])];
  };

  const parcelContextData = (selectedParcel, isChildrenParcel = false) => {
    const referenceParcel = exploitationParcels?.find((parcel) => parcel.id === selectedParcel.id);
    let isGroupedParcel = false;
    let groupSurface = 0;
    let groupChildrenParcels = [];
    let cultures = [];
    if (selectedParcel?.hasOwnProperty('isParent') && selectedParcel?.isParent === true) {
      isGroupedParcel = true;
      groupSurface = selectedParcel?.surface;
      groupChildrenParcels = exploitationParcels
        ?.filter((parcel) => parcel?.parent === selectedParcel?.id)
        .map((parcel) => parcel?.id);
    }
    if (selectedParcel?.hasOwnProperty('parent')) {
      isGroupedParcel = true;
      groupSurface = suggest?.parcels?.find(
        (parcel) => parcel?.id === selectedParcel?.parent
      )?.surface;
      groupChildrenParcels = exploitationParcels
        ?.filter((parcel) => parcel?.parent === selectedParcel?.parent)
        .map((parcel) => parcel?.id);
      cultures = suggest?.parcels.find((parcel) => parcel?.id === selectedParcel?.parent)?.cultures;
    }

    let selectedParcelData = {
      ...selectedParcel,
      coordinates: exploitationParcels ? referenceParcel?.coordinates : 'Pas de culture à afficher',
      cultureN: {
        name: exploitationParcels ? referenceParcel?.cultureN?.name : 'Pas de culture à afficher',
        id: exploitationParcels ? referenceParcel?.cultureN?.id : '',
        isIrrigable: exploitationParcels ? referenceParcel?.cultureN?.isIrrigable : false,
        year: currentYear,
      },
      cultureN1: {
        name: exploitationParcels ? referenceParcel?.cultureN1?.name : 'Pas de culture à afficher',
        id: exploitationParcels ? referenceParcel?.cultureN1?.id : '',
        year: currentYear - 1,
      },
      groundType: {
        name: exploitationParcels ? referenceParcel?.groundType.name : 'Pas de culture à afficher',
        id: exploitationParcels ? referenceParcel?.groundType.id : '',
      },
      isIrrigableOnly: exploitationParcels ? referenceParcel?.isIrrigable : false,
      surface: exploitationParcels ? referenceParcel?.surface : 'inconnu',
      isGroupedParcel: isGroupedParcel,
    };

    if (isGroupedParcel) {
      selectedParcelData = {
        ...selectedParcelData,
        groupChildrenParcels: groupChildrenParcels ?? [],
        groupSurface: groupSurface ?? 'inconnu',
      };

      if (isChildrenParcel) {
        selectedParcelData.cultures = cultures ?? [];
      }
    }

    return selectedParcelData;
  };

  const updateFromMap = (parcelId, updatedCultures, modalyear) => {
    const indxParcel = suggest?.parcels.indexOf(suggest?.parcels.find((c) => c.id === parcelId));
    const updatedSuggest = structuredClone(suggest);
    const cultureIndex = suggest?.parcels[indxParcel].cultures.indexOf(
      suggest?.parcels[indxParcel].cultures.find((culture) => culture.year === modalyear)
    );

    updatedSuggest.parcels[indxParcel].cultures[cultureIndex] = updatedCultures;

    setSuggest(updatedSuggest);
    setOther(JSON.parse(JSON.stringify(DEFAULT_OTHER_TABLE)));
    setOtherNitrogen([]);
    setPac([]);
    setPacHelps([]);
    setHasChange(true);
    setModalMap(false);
  };

  return loading ? (
    <div>
      <Loader />
      {jobId && <p className="job_id_display text-center">Job Id : {jobId}</p>}
    </div>
  ) : (
    <div className="Content">
      <div className="Result">
        {currentCooperative?.logo ? (
          <img
            src={currentCooperative?.logo}
            className="logo"
            alt={`Logo ${currentCooperative?.name}`}
          />
        ) : (
          <></>
        )}

        <div className="flex align-items-center">
          <p className="title">Simulation</p>
          <ModalContext.Provider
            value={{
              showModal,
              toggleModal,
              setConfirmSendReport,
              setListMailUserFromModal,
            }}
          >
            {minimalRoleRequired(user.role, ROLE_TECHNICIAN) && (
              <div style={{ marginLeft: '35px' }}>
                <button onClick={() => toggleModal()}>Envoi Simulation</button>
              </div>
            )}
            {showModal && (
              <ReportModal techniciansAddressMailList={techniciansAddressMailList}></ReportModal>
            )}
          </ModalContext.Provider>
        </div>
        <div className="switchSuggest">
          <p
            className={suggest === result.suggestions.suggestion1 ? 'active' : ''}
            onClick={() => changeSuggestion(result.suggestions.suggestion1, 'suggestion1')}
          >
            Proposition 1
          </p>
          {/* <p
            className={suggest === result.suggestions.suggestion2 ? 'active' : ''}
            onClick={() => changeSuggestion(result.suggestions.suggestion2, 'suggestion2')}
          >
            Proposition 2
          </p>
          <p
            className={suggest === result.suggestions.suggestion3 ? 'active' : ''}
            onClick={() => changeSuggestion(result.suggestions.suggestion3, 'suggestion3')}
          >
            Proposition 3
            </p>*/}
        </div>
        <div className="resultContent">
          {false ? (
            <div className="values">
              {false ? (
                <div>
                  <p className="price">{} ??K€</p>
                  <p>Chiffre d'affaires</p>
                </div>
              ) : (
                <div />
              )}
              {false ? (
                <div>
                  <p className="price">{} ??K€</p>
                  <p>Chiffre d'affaires</p>
                </div>
              ) : (
                <div />
              )}
              {false ? (
                <div>
                  <p className="price">{} ??K€</p>
                  <p>Revenu espéré</p>
                </div>
              ) : (
                <div />
              )}
            </div>
          ) : (
            <div />
          )}
          <h2>Marge Brute</h2>
          <div className="lineChart" id="marge-graph">
            <ResponsiveLine
              // id="marge-graph"
              data={lineGraphData}
              margin={{
                top: 50,
                right: 50,
                bottom: 110,
                left: 60,
              }}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                min: yScale.min - Math.abs(yScale.min * 0.1),
                max: yScale.max + Math.abs(yScale.max * 0.1),
                stacked: true,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              enableGridX={false}
              pointSize={10}
              pointColor={{ from: 'color' }}
              pointBorderWidth={3}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              useMesh
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 100,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'square',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <DataGridKeyNumber
            rows={other}
            columns={[
              ...(previousData?.capPoints?.length > 1
                ? [
                    'Campagne',
                    `${currentYear - 2} - ${currentYear - 1}`,
                    `${currentYear} - ${currentYear + 1}`,
                    `${currentYear + 1} - ${currentYear + 2}`,
                  ]
                : [
                    'Campagne',
                    `${currentYear - 1} - ${currentYear}`,
                    `${currentYear} - ${currentYear + 1}`,
                  ]),
              ...Array(suggest?.years.length - 1)
                .fill()
                .map((_, i) => `${i + (currentYear + 1)}-${i + (currentYear + 2)}`),
            ]}
          />
          {isNitrogenASelectedConstraint && (
            <DataGridKeyNumberNitrogen
              rows={otherNitrogen}
              columns={[
                ...(previousData?.capPoints?.length > 1
                  ? [
                      'Campagne',
                      `${currentYear - 2} - ${currentYear - 1}`,
                      `${currentYear} - ${currentYear + 1}`,
                      `${currentYear + 1} - ${currentYear + 2}`,
                    ]
                  : [
                      'Campagne',
                      `${currentYear - 1} - ${currentYear}`,
                      `${currentYear} - ${currentYear + 1}`,
                    ]),
                ...Array(suggest?.years.length - 1)
                  .fill()
                  .map((_, i) => `${i + (currentYear + 1)} - ${i + (currentYear + 2)}`),
              ]}
            />
          )}
          {pac.length !== 0 ? (
            <DataGridCAPPoints
              rows={pac}
              columns={[
                ...(previousData?.capPoints?.length > 1
                  ? [
                      'Campagne',
                      `${currentYear - 2} - ${currentYear - 1}`,
                      `${currentYear - 1} - ${currentYear}`,
                      `${currentYear} - ${currentYear + 1}`,
                    ]
                  : [
                      'Campagne',
                      `${currentYear - 1} - ${currentYear}`,
                      `${currentYear} - ${currentYear + 1}`,
                    ]),
                ...Array(suggest?.years.length - 1)
                  .fill()
                  .map((_, i) => `${i + (currentYear + 1)}-${i + (currentYear + 2)}`),
              ]}
            />
          ) : null}

          {pacHelps.length !== 0 ? <DataGridCAPHelp rows={pacHelps} /> : null}
          <h2>Production (ha)</h2>
          <div className="pieChartContainer">
            <div className="switchYear" ref={pieChartContainerRef}>
              <div key={v1()}>
                <p
                  className={0 === selectedYear ? 'active' : ''}
                  onClick={() => setSelectedYear(0)}
                >
                  Campagne {currentYear - 1}-{currentYear}
                </p>
              </div>
              {suggest.years.map((_, i) => (
                <div key={v1()}>
                  <p
                    className={i + 1 === selectedYear ? 'active' : ''}
                    onClick={() => setSelectedYear(i + 1)}
                  >
                    Campagne {currentYear + i}-{currentYear + i + 1}
                  </p>
                </div>
              ))}
            </div>
            <div className="pieChart" id="graph-production">
              <ResponsivePie
                data={[
                  previousData?.hectaresDistribution?.map((cultureDistrib) => {
                    return {
                      ...cultureDistrib,
                      color: '#' + getColorById(cultureDistrib.culture?.id, culturesColors),
                    };
                  }),
                ]
                  .concat(
                    suggest?.years.map((year) =>
                      year.hectaresDistribution.map((hecta) => {
                        return {
                          ...hecta,
                          color: '#' + getColorById(hecta.culture?.id, culturesColors),
                        };
                      })
                    )
                  )
                  [selectedYear].filter((a) => a.hectares > 0)
                  .map((a) => ({
                    value: a.hectares,
                    id: a.culture.name,
                    color: a.color,
                  }))}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                colors={{ datum: 'data.color' }}
                innerRadius={0.8}
                radialLabel={(d) => {
                  return `${d.label}: ${d.value} ha`;
                }}
                borderWidth={1}
                padAngle={0.7}
                cornerRadius={3}
                enableSliceLabels={false}
                animate={false}
              />
            </div>
            <div className="pieChart_table">
              <MapTab
                data={getMapParcelsData()}
                header={['', 'Culture', 'Marge']}
                suggestions={suggest?.years}
                isDynamic={false}
                year={currentYear + 1}
                yearIndex={selectedYear - 1}
                fromProduction
                pieChartContainerHeight={pieChartContainerHeight}
                previousCropMargin={previousData?.cropGrossMargin[0]}
              />
            </div>
          </div>
          <section>
            <div className="add_button_container">
              <IconButton
                text="Synthèses"
                onClick={() => downloadPDF()}
                icon={<img src={pdfIcon} className="pdfIcon" alt="PDF icon" />}
              />
            </div>
            {modal && (
              <FormModal
                onClick={() => {
                  setModal(false);
                }}
                customTemplate={
                  <div>
                    <SimulationCard title="Sélection de la propositon à exporter">
                      {proposals.map((e, i) => (
                        <Checkbox
                          key={i}
                          checked={selectedProposals.filter((proposal) => e === proposal).length}
                          onChange={toggleProposals}
                          value={e}
                          label={`Proposition ${i + 1}`}
                        />
                      ))}
                      <hr />
                    </SimulationCard>
                    <Button
                      type="submit"
                      text="Valider"
                      onClick={() => downloadPDF()}
                      disabled={!(selectedProposals.length > 0)}
                    />
                  </div>
                }
              ></FormModal>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
              }}
            >
              <h2>Synthèse par parcelle</h2>
              <div className="map_button_container">
                <Button
                  defaultStyle="group_button"
                  text="Liste"
                  onClick={() => {
                    setMap(false);
                  }}
                />
                {!!coordsExist && (
                  <Button
                    defaultStyle="group_button"
                    text="Carte"
                    onClick={() => {
                      setMap(true);
                    }}
                  />
                )}
              </div>

              <Button
                text="Recalculer"
                defaultStyle="button-synthesis"
                disabled={!hasChange}
                onClick={() => launchCalculette()}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content',
                marginLeft: '80px',
              }}
            >
              {suggest?.years.map((y, idx) =>
                // Iterate for all years suggestion
                y.errorsConstraints
                  ? Object.keys(y.errorsConstraints).map((constraintName) =>
                      // When Error Constraint are found, iterate for some sub specificity of the constraint
                      // eg : Error Constraint on coProductType can have issue with Paille or/and Ensillage
                      Object.keys(y.errorsConstraints[constraintName]).map(
                        (constraintDetailName) => (
                          <RenderConstraintNotRespected
                            year={y}
                            index={idx}
                            constraintName={constraintName}
                            constraintDetailName={constraintDetailName}
                            suggest={suggest}
                            result={result}
                          />
                        )
                      )
                    )
                  : null
              )}
            </div>
            {!!coordsExist && (
              <div style={{ display: mapTab ? 'block' : 'none' }}>
                <MapResults
                  hasCulturesList={true}
                  parcelUpdated={null}
                  setModalData={setModalData}
                  data={getMapParcelsData()}
                  nbYear={result?.nbYear}
                  setMapYear={setMapYear}
                  center={mapTab}
                  suggestions={suggest?.years}
                  previousCropMargin={previousData?.cropGrossMargin?.[0]}
                  currentYear={currentYear}
                ></MapResults>
              </div>
            )}
            {modalMap && (
              <ResultsInfo
                modal={modalMap}
                setModal={setModalMap}
                updateFromMap={updateFromMap}
                resultsInfo={editData}
                cultures={cultures}
                year={mapYear}
              />
            )}
            <div style={{ display: mapTab ? 'none' : 'block' }}>
              {isCoverProposalASelectedConstraint && (
                <div className="legend_simulation_synthesis" style={{ margin: '10px' }}>
                  <div className="align-cover">
                    <img
                      src={logoCover}
                      alt="Logo couvert"
                      className="not-disabled-cover cover-proposal"
                    />
                    Couvert à base légumineuse avant la culture
                  </div>
                  <div className="align-cover">
                    <img
                      src={logoCover}
                      alt="Logo couvert"
                      className="disabled-cover cover-proposal"
                    />
                    Autre couvert possible avant la culture
                  </div>
                </div>
              )}
              <DataGridParcelSynthesis
                columns={[
                  ...['Parcelle', 'Surface'],
                  ...Array(suggest?.years.length + 1)
                    .fill()
                    .map((_, i) => `${i + currentYear - 1}-${i + currentYear}`),
                ]}
                rows={suggest?.parcels.map((selectedParcel) => {
                  return {
                    ...selectedParcel,
                    cultureN: exploitationParcels
                      ? exploitationParcels?.find((parcel) => parcel.id === selectedParcel.id)
                          .cultureN.name
                      : 'Pas de culture à afficher',
                  };
                })}
                cultures={cultures}
                setOther={setOther}
                setPac={setPac}
                setPacHelps={setPacHelps}
                defaultOtherTable={DEFAULT_OTHER_TABLE}
                irrigationInformation={suggest?.years}
                setHasChange={setHasChange}
                suggest={suggest}
                setSuggest={setSuggest}
                exploitationContext={exploitationContext}
                editedSynthesisCellsRef={editedSynthesisCellsRef}
              />
              <p className="legend_simulation_synthesis">
                <span>*</span> Parcelle parente d'un regroupement.
              </p>
            </div>
            {/* Permet d'augmenter la hauteur de la section pour éviter qu'elle s'arrête au tableau */}
          </section>
          <div>
            {jobId && <div className="job_id_display job_display_margin">Job id: {jobId}</div>}
          </div>
          {/*
          <h2>Notes</h2>
          <div>
            <label htmlFor='most'>
              Les plus
              <textarea name='most'/>
            </label>
            <label htmlFor='least'>
              Les moins
              <textarea name='least' />
            </label>
          </div>
          <label htmlFor='other'>
            Recommandations et plans
            <textarea name='other'/>
          </label> */}
        </div>
      </div>
    </div>
  );
};

Result.propTypes = {};

export default Result;
