import CustomDataGrid from 'utils/Datagrid/CustomCultureDataGrid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CoProductEditCell,
  CoProductYieldEditCell,
  CustomCultureColorCell,
  CustomCultureInputCostCell,
  CustomCulturePriceCell,
  CustomEditCulturePriceCell,
  CustomHeaderWithoutSort,
  customCoProductsCell,
  copyCurrentValueToFuture,
  EditInputCell,
  StyledBadge,
  handleRowUpdateValidation,
  EditNameCell,
  CustomNameCell,
  CustomCultureIFTCell,
  CustomNitrogenSupplyByCover,
  CustomEditCapCategoryCell,
  CustomEditIsArableCell,
  CustomGrossMarginCell,
  renderResetDialog,
} from 'utils/Datagrid/CustomCultureDataGridUtils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import './cultures.styles.scss';
import useCustomEditColorCell from 'utils/Datagrid/useCustomEditColorCell';
import { updateCulture } from 'services/API/Cooperative';
import {
  resetSectorCultures,
  updateCulture as updateSectorCulture,
} from '../../../services/API/Secteur';
import {
  resetCultures,
  updateCulture as updateExploitationCulture,
} from '../../../services/API/Exploitation';
import {
  checkDecimalNegativeDatagrid,
  checkDecimalRegexTwoDigitsDatagridValue,
  checkEmptyFieldAndNoDecimalDatagridInteger,
  checkNoDecimalRegexTwoDigitsDatagridValue,
  checkTypeStringRegexDatagrid,
  displayErrorMessage,
  getOnlyAttributeUpdated,
  getStringInsideParentheses,
} from 'utils/tools_functions';
import Button from '@mui/material/Button';
import {
  inputAbsolutePositiveFloatValidator,
  inputDurationValidator,
  inputAllowArableValidator,
  inputCapCategoryAllowArableValidator,
  inputExtraCostValidator,
  inputIFTValidator,
  inputIntegerValidator,
  inputNitrogenValidator,
  inputNumberRequired,
  inputPositiveFloatValidator,
  inputPositiveNotNullFloatValidator,
  inputRequired,
  inputVerifyName,
  inputYieldValidator,
} from 'components/generics/Validators/validator';
import {
  validateDatagridData,
  validateDatagridDataCapCategory,
} from '../../generics/Validators/datagrid.validate';
import InputCostModalNitrogen from './form/InputCostModalNitrogen';
import {
  CustomSortableHeader,
  processColumns,
  resetErrorsForm,
} from 'utils/Datagrid/CustomGenericDataGridUtils';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import useDataGridCulturesActions from 'utils/Datagrid/useDataGridCulturesActions';
import useDataGridCultureVisibility from 'utils/Datagrid/useDataGridCultureVisibility';
import AddCultureModalNitrogen from './form/AddCultureModalNitrogen';
import IFTModal from './form/IFTModal';
import { Box, InputLabel, TextField } from '@mui/material';
import NitrogenPriceModal from './form/NitrogenPriceModal';
import { customGenericInputTheme } from 'assets/styles/themes/Dialog/generic_input_mui';
import InputCostModal from './form/InputCostModal';
import AddCultureModal from './form/AddCultureModal';

const DataGridCulture = ({
  columnsDefinition,
  from,
  cultures,
  refreshData,
  user,
  sector,
  exploitation,
  CAPCategories,
  coProductsList,
  structureId,
  setCoProductsList,
  economicAttribute,
  cooperativeId,
  cooperativeName,
  nitrogenCover,
  getEconomicAttribute,
}) => {
  const [rows, setRows] = useState([]);
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  const [addCultureModal, setAddCultureModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const renderColorEditCell = useCustomEditColorCell(cultures);
  const [openInputCostDialog, setOpenInputCostDialog] = useState(false);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired, inputVerifyName],
    },
    inputCostWithoutNitrogen: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    inputCostWithoutNitrogenN5: {
      message: '',
      validator: [inputPositiveFloatValidator],
    },
    extraCost: {
      message: '',
      validator: [inputExtraCostValidator],
    },
    tfiHerbicide: {
      message: '',
      validator: [inputPositiveNotNullFloatValidator, inputIFTValidator],
    },
    tfiWithoutHerbicide: {
      message: '',
      validator: [inputPositiveNotNullFloatValidator, inputIFTValidator],
    },
    presenceDuration: {
      message: '',
      validator: [inputNumberRequired, inputIntegerValidator, inputDurationValidator],
    },
    prohibitionDuration: {
      message: '',
      validator: [inputNumberRequired, inputIntegerValidator, inputDurationValidator],
    },
    yield: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator, inputYieldValidator],
    },
    irrigationYieldGain: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    irrigationWaterNeeded: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    nitrogenBonus: {
      message: '',
      validator: [inputIntegerValidator],
    },
    nitrogenLimit: {
      message: '',
      validator: [inputIntegerValidator],
    },
    necessaryNitrogenQuantity: {
      message: '',
      validator: [inputIntegerValidator, inputNitrogenValidator],
    },
  });
  const [errorsFormCoProduct, setErrorsFormCoProduct] = useState({
    formError: false,
    yield: {
      message: '',
      validator: [inputNumberRequired, inputAbsolutePositiveFloatValidator],
    },
  });
  const [errorCoProductsMessages, setErrorCoProductsMessages] = useState([]);
  const [errorsFormEconomicValue, setErrorsFormEconomicValue] = useState({
    formError: false,
    price: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    priceN5: {
      message: '',
      validator: [inputPositiveFloatValidator],
    },
  });
  const [errorsFormNitrogenEconomicValue, setErrorsNitrogenFormEconomicValue] = useState({
    formError: false,
    nitrogenPrice: {
      message: '',
      validator: [inputPositiveFloatValidator],
    },
    nitrogenPriceN5: {
      message: '',
      validator: [inputPositiveFloatValidator],
    },
  });
  const [errorsFormNitrogenCoverValue, setErrorsNitrogenCoverValue] = useState({
    formError: false,
    nitrogenSupplyByCover: {
      message: '',
      validator: [inputIntegerValidator],
    },
  });
  const [tabPriceValues, setTabPriceValues] = useState([]);
  const [tabInputCostValues, setTabInputCostValues] = useState([]);
  const [errorsFormCapCategory, setErrorsFormCapCategory] = useState({
    formError: false,
    capCategory: {
      message: '',
      validator: [inputCapCategoryAllowArableValidator],
    },
    isArable: {
      message: '',
      validator: [inputAllowArableValidator],
    },
  });
  const priceTabsValuesRef = useRef([]);
  const grossMarginTabsValuesRef = useRef([]);
  const inputCostTabsValuesRef = useRef([]);
  const [inputCostValue, setInputCostValue] = useState({
    inputCost: 0,
    inputCostN5: 0,
    inputCostWithoutNitrogen: 0,
    inputCostWithoutNitrogenN5: 0,
    necessaryNitrogenQuantity: 0,
    cultureId: 0,
  });
  const [paramsInputCost, setParamsInputCost] = useState({
    inputCost: false,
    inputCostN5: false,
  });
  const [isEditCoProduct, setIsEditCoProduct] = useState(false);
  const [isEditInputCost, setIsEditInputCost] = useState(false);
  const [isEditableIFT, setIsEditableIFT] = useState(false);
  const [openIFTDialog, setOpenIFTDialog] = useState(false);
  const [IFTValue, setIFTtValue] = useState({
    tfiHerbicide: 0,
    tfiWithoutHerbicide: 0,
    cultureId: 0,
  });
  const [openNitrogenPriceDialog, setOpenNitrogenPriceDialog] = useState(false);
  const [nitrogenPriceValue, setNitrogenPriceValue] = useState(economicAttribute);
  const [coProductYieldFocus, setCoProductYieldFocus] = useState({});
  const [nitrogenCoverValue, setNitrogenCoverValue] = useState(nitrogenCover);
  const [isEditNitrogenCover, setIsEditNitrogenCover] = useState(false);
  const [nitrogenToggle, setNitrogenToggle] = useState(
    localStorage.getItem('nitrogenOption') === 'true'
  );
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const {
    rowModesModel,
    setRowModesModel,
    getActions,
    renderDeleteDialog,
    renderDuplicateDialog,
    renderDeleteEffectsDialog,
  } = useDataGridCulturesActions(
    user,
    rows,
    refreshData,
    cultures,
    errorsFormDefault,
    errorsFormCoProduct,
    errorsFormEconomicValue,
    errorsFormCapCategory,
    from === 'fromCooperative' ? true : false,
    from === 'fromExploitation' ? true : false,
    exploitation
  );

  useEffect(() => {
    if (rows.length) {
      priceTabsValuesRef.current = rows.map((row) => ({ id: row.id, value: 0 }));
      inputCostTabsValuesRef.current = rows.map((row) => ({ id: row.id, value: 0 }));
      grossMarginTabsValuesRef.current = rows.map((row) => ({ id: row.id, value: 0 }));
    }
  }, [rows.length]);

  useEffect(() => {
    setDataGridLoading(true);
    if (lastSelectedRows.length && toggleVisibilityFilter) {
      processedRows &&
        setRows(processedRows?.filter((culture) => !lastSelectedRows.includes(culture.id)));
    } else {
      processedRows && setRows(processedRows);
    }
    setDataGridLoading(false);
  }, [cultures]);

  const processedRows = cultures?.map((culture) => {
    // Gross margin calculation
    const grossMargin =
      culture.economicAttributeCollection?.price?.currentEconomicValue * culture.yield -
      culture.economicAttributeCollection?.inputCost?.currentEconomicValue;

    const grossMarginN5 =
      (culture.economicAttributeCollection?.price?.futureEconomicValue ??
        culture.economicAttributeCollection?.price?.currentEconomicValue) *
        culture.yield -
      (culture.economicAttributeCollection?.inputCost?.futureEconomicValue ??
        culture.economicAttributeCollection?.inputCost?.currentEconomicValue);
    return {
      id: culture.id,
      name: culture.name,
      ...(culture.parentCultureName && { parentName: culture.parentCultureName }),
      color: culture.color,
      price: culture.economicAttributeCollection?.price?.currentEconomicValue ?? null,
      priceN5:
        culture.economicAttributeCollection?.price?.futureEconomicValue ??
        culture.economicAttributeCollection?.price?.currentEconomicValue ??
        null,
      inputCost: culture.economicAttributeCollection?.inputCost?.currentEconomicValue ?? null,
      inputCostN5:
        culture.economicAttributeCollection?.inputCost?.futureEconomicValue ??
        culture.economicAttributeCollection?.inputCost?.currentEconomicValue ??
        null,
      inputCostWithoutNitrogen:
        culture.economicAttributeCollection?.inputCostWithoutNitrogen?.currentEconomicValue ?? null,
      inputCostWithoutNitrogenN5:
        culture.economicAttributeCollection?.inputCostWithoutNitrogen?.futureEconomicValue ??
        culture.economicAttributeCollection?.inputCostWithoutNitrogen?.currentEconomicValue ??
        null,
      yield: culture.yield,
      nitrogenBonus: culture.nitrogenBonus ?? 0,
      nitrogenLimit: culture.nitrogenLimit ?? 0,
      necessaryNitrogenQuantity: culture.necessaryNitrogenQuantity,
      tfi: culture.tfi,
      tfiHerbicide: culture.tfiHerbicide,
      tfiWithoutHerbicide: culture.tfiWithoutHerbicide,
      presenceDuration: culture.presenceDuration,
      prohibitionDuration: culture.prohibitionDuration,
      coProducts: culture.coProducts,
      coProduct: culture.coProducts?.map((coProduct) => coProduct.displayName),
      yieldProduct: culture.coProducts?.map((coProduct) => coProduct.yield),
      irrigationYieldGain: culture.irrigationYieldGain,
      irrigationWaterNeeded: culture.irrigationWaterNeeded,
      capCategory: culture.capCategory?.displayName ?? 'Aucune',
      isSummerCulture:
        culture.isSummerCulture === -1 ? 'Aucune' : culture.isSummerCulture === 1 ? 'Été' : 'Hiver',
      isStrawProducer: culture.isStrawProducer,
      extraCost: culture.extraCost,
      isIrrigableOnly: culture.isIrrigableOnly,
      isArable: culture.isArable,
      grossMargin: grossMargin,
      grossMarginN5: grossMarginN5,
    };
  });

  const isOpenYieldCoProduct = errorsFormCoProduct.formError;
  const isOpen = errorsFormDefault.formError;
  const isOpenPrice = errorsFormEconomicValue.formError;

  const renderPriceCell = (params) => {
    return (
      <CustomCulturePriceCell
        priceTabsValues={priceTabsValuesRef}
        id={params.id}
        value={params.value}
        row={params.row}
      />
    );
  };

  const renderGrossMarginCell = (params) => {
    return (
      <CustomGrossMarginCell {...params} grossMarginTabsValuesRef={grossMarginTabsValuesRef} />
    );
  };

  const renderCoProductYieldEditCell = (params) => {
    return (
      <CoProductYieldEditCell
        errorsFormCoProduct={errorsFormCoProduct}
        errorCoProductsMessages={errorCoProductsMessages}
        isOpen={isOpenYieldCoProduct}
        coProductYieldFocus={coProductYieldFocus}
        {...params}
      />
    );
  };

  const renderEditPriceCell = (params) => {
    return (
      <CustomEditCulturePriceCell
        params={params}
        errorsFormEconomicValue={errorsFormEconomicValue}
        isOpen={isOpenPrice}
        priceTabsValuesRef={priceTabsValuesRef}
      />
    );
  };

  const renderNameEditCell = (params) => {
    return <EditNameCell params={params} errorsFormDefault={errorsFormDefault} isOpen={isOpen} />;
  };

  const renderCoProductEditCell = (params) => {
    return (
      <CoProductEditCell
        coProductsList={coProductsList}
        setIsEditCoProduct={setIsEditCoProduct}
        {...params}
      />
    );
  };

  const renderCapCategoryEditCell = (params) => {
    return (
      <CustomEditCapCategoryCell
        errorsFormCapCategory={errorsFormCapCategory}
        CAPCategories={CAPCategories}
        {...params}
      />
    );
  };

  const renderIsArableEditCell = (params) => {
    return <CustomEditIsArableCell errorsFormCapCategory={errorsFormCapCategory} {...params} />;
  };

  const {
    toggleVisibilityFilter,
    columnVisible,
    selectedRows,
    setSelectedRows,
    setColumnVisible,
    handleVisibility,
    handleCulturesHide,
    handleCulturesShow,
    badgeContent,
    isLargeGrid,
    lastSelectedRows,
  } = useDataGridCultureVisibility(rows, setRows, processedRows, from, user);

  const allColumns = [
    {
      field: 'name',
      headerName: 'Nom',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      cellClassName: 'culture-center-cell',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 180,
      sortable: false,
      editable: true,
      renderCell: (params) => CustomNameCell(params.row.name),
      valueParser: (value) => {
        return checkTypeStringRegexDatagrid(value);
      },
      renderEditCell: (params) =>
        params.row?.parentName
          ? renderNameEditCell(params)
          : EditInputCell(params, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'name'} name={'Nom'} />
      ),
    },
    {
      field: 'color',
      headerName: 'Couleur',
      type: 'string',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 130,
      sortable: false,
      editable: true,
      renderCell: (params) => CustomCultureColorCell(params?.row?.color),
      renderEditCell: renderColorEditCell,
      renderHeader: () => <CustomHeaderWithoutSort name={'Couleur'} />,
    },
    {
      field: 'price',
      headerName: 'Prix de vente (€/t)',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderCell: renderPriceCell,
      renderEditCell: renderEditPriceCell,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'price'}
          name={'Prix de vente (€/t)'}
        />
      ),
    },
    {
      field: 'priceN5',
      headerName: 'Prix de vente N5 (€/t)',
      type: 'number',
      sortable: false,
      editable: true,
    },
    {
      field: 'grossMargin',
      headerName: 'Marge Brute (€/ha)',
      headerClassName: isLargeGrid ? 'culture-header-full' : 'culture-header-light',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: false,
      renderCell: renderGrossMarginCell,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'grossMargin'}
          optionnalName={'Marge brute = prix de vente * rendement - coût'}
          name={'Marge Brute (€/ha)'}
        />
      ),
    },
    {
      field: 'grossMarginN5',
      headerName: 'Marge brute N5 (€/ha)',
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'inputCost',
      headerName: 'Charges (€/ha)',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        CustomCultureInputCostCell(
          params,
          openInputCostDialog,
          setOpenInputCostDialog,
          setInputCostValue,
          setParamsInputCost,
          isEditInputCost,
          inputCostTabsValuesRef
        ),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'inputCost'}
          optionnalName={'Cout = charges intrants + semences'}
          name={'Charges (€/ha)'}
        />
      ),
    },
    {
      field: 'inputCostN5',
      headerName: 'Charges N5 (€/ha)',
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'inputCostWithoutNitrogen',
      headerName: ' Coût intrant hors Azote (€/ha)',
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'inputCostWithoutNitrogenN5',
      headerName: ' Coût intrant hors Azote N+5 (€/ha)',
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'yield',
      headerName: 'Rendement (t/ha)',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'yield'}
          optionnalName={'Rendement moyen (t/ha)'}
          name={'Rendement (t/ha)'}
        />
      ),
    },
    {
      field: 'nitrogenBonus',
      headerName: 'Bonus azote (u/ha)',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkEmptyFieldAndNoDecimalDatagridInteger(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'nitrogenBonus'}
          optionnalName={"Bonus d'azote apportée par la culture dans le sol (unité/hectare)"}
          name={'Bonus azote (u/ha)'}
        />
      ),
    },
    {
      field: 'nitrogenLimit',
      headerName: 'Limite azote (u/ha)',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkEmptyFieldAndNoDecimalDatagridInteger(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'nitrogenLimit'}
          optionnalName={
            "Limite d'azote provenant du sol absorbable par la culture (unité/hectare)"
          }
          name={'Limite azote (u/ha)'}
        />
      ),
    },
    {
      field: 'necessaryNitrogenQuantity',
      headerName: 'Besoin azote (u/ha)',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkNoDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'necessaryNitrogenQuantity'}
          optionnalName={"Besoin d'azote nécessaire pour produire la culture (unité/hectare)"}
          name={'Besoin azote (u/ha)'}
        />
      ),
    },
    {
      field: 'tfi',
      headerName: `IFT`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        CustomCultureIFTCell(params, isEditableIFT, openIFTDialog, setOpenIFTDialog, setIFTtValue),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'ift'}
          optionnalName={'Indice Fréquence Traitement'}
          name={`IFT`}
        />
      ),
    },
    {
      field: 'tfiHerbicide',
      headerName: `IFT Herbicides`,
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'tfiWithoutHerbicide',
      headerName: `IFT Hors Herbicides`,
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'presenceDuration',
      headerName: `Durée de présence`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkNoDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'presenceDuration'}
          optionnalName={'Temps durant lequel la culture va rester sur la parcelle (en année)'}
          name={`Durée de présence`}
        />
      ),
    },
    {
      field: 'prohibitionDuration',
      headerName: `Durée d'interdiction`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkNoDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'prohibitionDuration'}
          optionnalName={
            "Durée de non retour de la culture sur une même parcelle. Exemple: si durée d'interdiction de 2 ans, la culture pourra revenir au bout de la troisième année"
          }
          name={`Durée d'interdiction`}
        />
      ),
    },
    {
      field: 'coProduct',
      headerName: `Type co-produit`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      renderEditCell: renderCoProductEditCell,
      renderCell: (params) => customCoProductsCell(params.value, setCoProductYieldFocus),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'coProduct'}
          optionnalName={'Type coproduit. Exemple : Paille pour blé'}
          name={`Type coproduit`}
        />
      ),
    },
    {
      field: 'yieldProduct',
      headerName: `Rendement coproduit (%)`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      renderCell: (params) => customCoProductsCell(params.value, setCoProductYieldFocus),
      renderEditCell: renderCoProductYieldEditCell,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'yieldProduct'}
          optionnalName={"Correspond à l'indice de rendement par rapport au  rendement grain"}
          name={`Rendement coproduit`}
        />
      ),
    },
    {
      field: 'irrigationYieldGain',
      headerName: `Gain rendement si irrigué (%)`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'irrigationYieldGain'}
          optionnalName={'Pourcentage de rendement gagné si la culture est irriguée'}
          name={`Gain rendement si irrigué (%)`}
        />
      ),
    },
    {
      field: 'irrigationWaterNeeded',
      headerName: `Consommation eau si irrigué (mm/ha)`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueParser: (value) => {
        return checkDecimalRegexTwoDigitsDatagridValue(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'irrigationWaterNeeded'}
          optionnalName={"Quantité d'eau nécessaire pour irriguer la culture (en mm)"}
          name={`Consommation eau si irrigué (mm/ha)`}
        />
      ),
    },
    {
      field: 'capCategory',
      headerName: `Catégorie PAC`,
      type: 'singleSelect',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      cellClassName: 'culture-center-cell',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueOptions: () =>
        CAPCategories && CAPCategories.map((CAPCategory) => CAPCategory.displayName),
      renderEditCell: renderCapCategoryEditCell,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'capCategory'}
          name={`Catégorie PAC`}
        />
      ),
    },
    {
      field: 'isSummerCulture',
      headerName: `Saison`,
      type: 'singleSelect',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      editable: true,
      sortable: false,
      valueOptions: () => ['Aucune', 'Été', 'Hiver'],
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isSummerCulture'}
          optionnalName={'Eté ou Hiver'}
          name={`Saison`}
        />
      ),
    },
    {
      field: 'isStrawProducer',
      headerName: 'Culture à paille',
      type: 'boolean',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      type: 'boolean',
      editable: true,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isStrawProducer'}
          name={'Culture à paille'}
        />
      ),
    },
    {
      field: 'extraCost',
      headerName: `Charges extra (€/ha)`,
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      editable: true,
      valueFormatter: (value) => (!value || value === 0 ? '-' : value),
      valueParser: (value) => {
        if (value.length === 0 || !value.length) {
          value = '0';
        }
        return checkDecimalNegativeDatagrid(value);
      },
      renderEditCell: (props) => EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'extraCost'}
          optionnalName={
            "Charge supplémentaire libre (exemple en valeur positive: l'emprunt de matériel en ETA) (exemple en valeur négative: primes PAC liées aux aides couplées)"
          }
          name={`Charges extra (€/ha)`}
        />
      ),
    },
    {
      field: 'isIrrigableOnly',
      headerName: 'Irrigation obligatoire',
      type: 'boolean',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      preProcessEditCellProps: (params) => {
        if (params.props.value) {
          setErrorsFormDefault({
            ...errorsFormDefault,
            irrigationWaterNeeded: {
              ...errorsFormDefault?.irrigationWaterNeeded,
              validator: [
                ...new Set([
                  ...errorsFormDefault?.irrigationWaterNeeded?.validator,
                  inputAbsolutePositiveFloatValidator,
                ]),
              ],
            },
          });

          return { ...params.props };
        }
        setErrorsFormDefault({
          ...errorsFormDefault,
          irrigationWaterNeeded: {
            ...errorsFormDefault?.irrigationWaterNeeded,
            validator: [inputNumberRequired, inputPositiveFloatValidator],
          },
        });

        return { ...params.props };
      },
      editable: true,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isIrrigableOnly'}
          name={'Irrigation obligatoire'}
        />
      ),
    },
    {
      field: 'isArable',
      headerName: 'Terre arable',
      type: 'boolean',
      headerClassName: `${isLargeGrid ? 'culture-header-full' : 'culture-header-light'}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 160,
      sortable: false,
      type: 'boolean',
      editable: true,
      renderEditCell: renderIsArableEditCell,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isArable'}
          name={'Terre arable'}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'actions',
      getActions: getActions,
      renderHeader: () => <CustomHeaderWithoutSort name={'Actions'} />,
    },
  ];

  const hiddenN5Columns = () => {
    const priceN5Column = allColumns.find((column) => column.field === 'priceN5');
    const inputCostN5Column = allColumns.find((column) => column.field === 'inputCostN5');
    const tfiHerbicideColumn = allColumns.find((column) => column.field === 'tfiHerbicide');
    const tfiWithoutHerbicideColumn = allColumns.find(
      (column) => column.field === 'tfiWithoutHerbicide'
    );
    const inputCostWithoutNitrogenColumn = allColumns.find(
      (column) => column.field === 'inputCostWithoutNitrogen'
    );
    const inputCostWithoutNitrogenN5Column = allColumns.find(
      (column) => column.field === 'inputCostWithoutNitrogenN5'
    );
    const grossMarginN5Column = allColumns.find((column) => column.field === 'grossMarginN5');

    return [
      priceN5Column,
      inputCostN5Column,
      tfiHerbicideColumn,
      tfiWithoutHerbicideColumn,
      inputCostWithoutNitrogenColumn,
      inputCostWithoutNitrogenN5Column,
      grossMarginN5Column,
    ];
  };

  const handleExceptionsRights = (data) => {
    return data.map((processedColumn) => {
      if (processedColumn.field === 'capCategory' && from === 'fromExploitation') {
        return { ...processedColumn, editable: false };
      }
      if (processedColumn.field === 'coProduct' && from === 'fromExploitation') {
        return { ...processedColumn, editable: false };
      }
      if (processedColumn.field === 'tfi') {
        return { ...processedColumn, editable: true };
      }
      if (processedColumn.field === 'inputCost') {
        return { ...processedColumn, editable: true };
      }
      return processedColumn;
    });
  };

  const processedColumns = handleExceptionsRights([
    ...processColumns(allColumns, columnsDefinition),
    ...hiddenN5Columns(),
  ]);

  const isInputCostEditable = processedColumns.some(
    (processedColumn) => processedColumn.field === 'inputCost' && processedColumn.editable
  );

  const isIFTEditable = processedColumns.some(
    (processedColumn) => processedColumn.field === 'tfi' && processedColumn.editable
  );

  useEffect(() => {
    if (isInputCostEditable) {
      setIsEditInputCost(true);
    }
    if (isIFTEditable) {
      setIsEditableIFT(true);
    }
  }, [isInputCostEditable, isIFTEditable]);

  const formSuccess = () => {
    setAddCultureModal(false);
    setEditData(null);
    refreshData();
  };

  const processRowUpdate = useCallback(
    async (updatedRow, previousRow) => {
      errorsFormDefault.formError = false;
      errorsFormCoProduct.formError = false;
      errorsFormEconomicValue.formError = false;
      errorsFormCapCategory.formError = false;
      setErrorCoProductsMessages([]);

      const cultureId = cultures.find((culture) => culture.id === updatedRow.id)?.id;
      const foundCapCategory = cultures.find((category) => category?.id === updatedRow.id)
        ?.capCategory?.id;
      const foundIsArable = cultures.find((culture) => culture?.id === updatedRow.id)?.isArable;
      const foundCapCategoryInRow = CAPCategories?.find(
        (category) => category?.id === foundCapCategory
      );
      const toUpdate = getOnlyAttributeUpdated(previousRow, updatedRow);
      // temporary force irrigationWaterNeeded in toUpdate to check this property even if not updated
      if (!toUpdate.hasOwnProperty('irrigationWaterNeeded')) {
        toUpdate.irrigationWaterNeeded = previousRow.irrigationWaterNeeded;
      }
      const foundCapCategoryInUpdate = CAPCategories?.find(
        (CAPCategory) => CAPCategory?.displayName === toUpdate?.capCategory
      );

      if (Object.entries(toUpdate).length > 0) {
        if (updatedRow?.parentName && toUpdate.hasOwnProperty('name')) {
          toUpdate.name = getStringInsideParentheses(toUpdate.name) ?? toUpdate.name;
        }
        setErrorsFormDefault({
          ...validateDatagridData(toUpdate, errorsFormDefault, processedColumns, updatedRow.id),
        });
        setErrorsFormCapCategory({
          ...validateDatagridDataCapCategory(
            toUpdate,
            errorsFormCapCategory,
            processedColumns,
            updatedRow.id,
            foundCapCategoryInRow,
            foundIsArable,
            foundCapCategoryInUpdate
          ),
        });
        setErrorsFormEconomicValue({
          ...validateDatagridData(
            toUpdate,
            errorsFormEconomicValue,
            processedColumns,
            updatedRow.id
          ),
        });

        const isError =
          errorsFormDefault.formError ||
          errorsFormCoProduct.formError ||
          errorsFormEconomicValue.formError ||
          errorsFormCapCategory.formError;

        if (isError && Object.keys(rowModesModel).length > 1) {
          resetErrorsForm(
            errorsFormDefault,
            errorsFormEconomicValue,
            errorsFormCoProduct,
            errorsFormCapCategory
          );

          return previousRow;
        }

        if (toUpdate.hasOwnProperty('necessaryNitrogenQuantity')) {
          toUpdate.necessaryNitrogenQuantity =
            toUpdate.necessaryNitrogenQuantity ?? updatedRow.necessaryNitrogenQuantity;
        }

        if (toUpdate.hasOwnProperty('nitrogenBonus')) {
          toUpdate.nitrogenBonus = toUpdate.nitrogenBonus ?? updatedRow.nitrogenBonus;
        }

        if (toUpdate.hasOwnProperty('nitrogenLimit')) {
          toUpdate.nitrogenLimit = toUpdate.nitrogenLimit ?? updatedRow.nitrogenLimit;
        }

        if (toUpdate.hasOwnProperty('isSummerCulture')) {
          toUpdate.isSummerCulture =
            toUpdate.isSummerCulture === 'Aucune' ? -1 : toUpdate.isSummerCulture === 'Été' ? 1 : 0;
        }
        if (toUpdate.hasOwnProperty('capCategory')) {
          toUpdate.capCategory =
            toUpdate.capCategory === 'Aucune'
              ? null
              : CAPCategories.find(
                  (CAPCategory) => CAPCategory.displayName === toUpdate.capCategory
                ).id;
        }

        if (!toUpdate.hasOwnProperty('coProduct') && toUpdate.hasOwnProperty('yieldProduct')) {
          const updatedCoProducts = toUpdate.yieldProduct.map((_, index) => {
            const temp = updatedRow.coProducts[index];
            return {
              id: temp.id,
              name: temp.name,
              displayName: temp.displayName,
              yield: toUpdate.yieldProduct[index],
            };
          });
          toUpdate.coProducts = updatedCoProducts;
          delete toUpdate.yieldProduct;
          delete updatedRow.yieldProduct;
          delete updatedRow.coProduct;
        }
        if (toUpdate.hasOwnProperty('coProduct')) {
          const updatedCoProducts = toUpdate?.coProduct?.map((coProductItem, index) => {
            const temp = coProductsList.find(
              (coProductsListItem) => coProductsListItem?.displayName === coProductItem
            );

            return {
              id: temp.id,
              name: temp.name,
              displayName: temp.displayName,
              yield: updatedRow.yieldProduct[index],
            };
          });

          toUpdate.coProducts = updatedCoProducts;
          delete toUpdate.coProduct;
        }

        let coProductErrorMessagesArray = [];
        if (toUpdate.hasOwnProperty('coProducts')) {
          for (const key in toUpdate.coProducts) {
            const validateCoProduct = validateDatagridData(
              toUpdate.coProducts[key],
              errorsFormCoProduct,
              processedColumns,
              updatedRow.id,
              toUpdate.coProducts[key].displayName
            );
            if (validateCoProduct.yield.message) {
              coProductErrorMessagesArray[key] = validateCoProduct.yield.message;
            }
            setErrorsFormCoProduct({
              ...validateCoProduct,
            });
          }
          setErrorCoProductsMessages(coProductErrorMessagesArray);
        }
        if (toUpdate.hasOwnProperty('price') && !toUpdate.hasOwnProperty('priceN5')) {
          toUpdate.economicAttributeCollection = {
            price: {
              currentEconomicValue: toUpdate.price ?? updatedRow.price,
              futureEconomicValue: toUpdate.price ?? updatedRow.priceN5,
            },
          };
          toUpdate.hasOwnProperty('price') && delete toUpdate.price;
        } else if (toUpdate.hasOwnProperty('price') || toUpdate.hasOwnProperty('priceN5')) {
          toUpdate.economicAttributeCollection = {
            price: {
              currentEconomicValue: toUpdate.price ?? updatedRow.price,
              futureEconomicValue: toUpdate.hasOwnProperty('priceN5')
                ? copyCurrentValueToFuture(toUpdate.priceN5, updatedRow.price)
                : updatedRow.priceN5,
            },
          };
          toUpdate.hasOwnProperty('price') && delete toUpdate.price;
          toUpdate.hasOwnProperty('priceN5') && delete toUpdate.priceN5;
        }

        await handleRowUpdateValidation(
          updatedRow,
          errorsFormDefault,
          errorsFormCoProduct,
          errorsFormEconomicValue,
          errorsFormCapCategory
        );

        try {
          switch (from) {
            case 'fromCooperative':
              await updateCulture(toUpdate, cultureId);
              break;
            case 'fromSector':
              await updateSectorCulture(sector.id, toUpdate, cultureId);
              break;
            case 'fromExploitation':
              await updateExploitationCulture(exploitation.id, toUpdate, cultureId);
              break;
          }
          toast.success(`Culture ${updatedRow.name} mise à jour`);
          refreshData();
        } catch (err) {
          if (
            err.response.data.message ===
            "A culture can't be arable with a CapCategory that doesn't allow arable"
          ) {
            toast.error(displayErrorMessage('ERR_ADD_CULTURE_CAP_CATEGORY'));
          } else {
            toast.error(err.response.data.message);
          }
        }

        return updatedRow;
      }

      return previousRow;
    },
    [cultures, CAPCategories, coProductsList, rowModesModel]
  );

  const resetAllCultures = async () => {
    setOpenResetDialog(false);

    try {
      if (from === 'fromSector') {
        await resetSectorCultures(sector);
        toast.success(`Cultures ${sector.name} réinitialisées`);
      } else if (from === 'fromExploitation') {
        await resetCultures(exploitation);
        toast.success(`Cultures ${exploitation.name} réinitialisées`);
      }
    } catch (error) {
      toast.error(displayErrorMessage('ERR_REINIT_CULTURE'));
    } finally {
      refreshData();
    }
  };

  useEffect(() => {
    setNitrogenPriceValue((prev) => ({
      ...prev,
      nitrogenPrice: economicAttribute?.nitrogenPrice?.currentEconomicValue ?? 0,
      nitrogenPriceN5:
        economicAttribute?.nitrogenPrice?.futureEconomicValue ??
        economicAttribute?.nitrogenPrice?.currentEconomicValue ??
        0,
    }));
  }, [economicAttribute]);

  useEffect(() => {
    setNitrogenCoverValue((prev) => ({
      ...prev,
      nitrogenSupplyByCover: nitrogenCover ?? 0,
    }));
  }, [nitrogenCover]);

  const handleToggleNitrogen = () => {
    setNitrogenToggle(!nitrogenToggle);
    localStorage.setItem('nitrogenOption', (!nitrogenToggle).toString());
  };

  return (
    cultures && (
      <div className="section">
        <div className="main_container">
          <div className="override">
            <h1 className="title_section title_section_culture">Liste des cultures</h1>
            <div className="contain_header_icon">
              {nitrogenToggle && (
                <div className="nitrogen_container">
                  <ThemeProvider theme={customGenericInputTheme}>
                    <Box>
                      <InputLabel id="nitrogenPriceLabel" htmlFor="nitrogenPrice">
                        Coût de l'Azote (€/U)
                      </InputLabel>
                      <TextField
                        value={nitrogenPriceValue?.nitrogenPrice}
                        InputProps={{
                          readOnly: true,
                        }}
                        onClick={() => setOpenNitrogenPriceDialog(!openNitrogenPriceDialog)}
                      />
                    </Box>
                    <CustomNitrogenSupplyByCover
                      getEconomicAttribute={getEconomicAttribute}
                      errorsFormNitrogenCoverValue={errorsFormNitrogenCoverValue}
                      setErrorsNitrogenCoverValue={setErrorsNitrogenCoverValue}
                      nitrogenCoverValue={nitrogenCoverValue}
                      owner={
                        from !== 'fromCooperative'
                          ? { parent: from, id: sector ? sector.id : exploitation.id }
                          : null
                      }
                      cooperativeId={cooperativeId}
                      cooperativeName={cooperativeName}
                      nitrogenCover={nitrogenCover}
                      isEditNitrogenCover={isEditNitrogenCover}
                      setIsEditNitrogenCover={setIsEditNitrogenCover}
                    />
                  </ThemeProvider>
                </div>
              )}
              <ThemeProvider theme={customButtonTheme}>
                <Box className="buttons_container">
                  <div className="mui_icon_header">
                    {toggleVisibilityFilter ? (
                      <Button onClick={handleCulturesShow}>
                        Afficher les cultures
                        <VisibilityOffIcon />
                        <StyledBadge badgeContent={badgeContent} color="primary"></StyledBadge>
                      </Button>
                    ) : (
                      <Button onClick={handleCulturesHide} disabled={!selectedRows.length}>
                        Masquer Des cultures
                        <VisibilityIcon />
                      </Button>
                    )}
                  </div>
                  <div className="mui_icon_header">
                    <Button onClick={handleToggleNitrogen}>
                      Options azote {nitrogenToggle ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </Button>
                  </div>
                  <div className="mui_icon_header">
                    <Button onClick={handleVisibility}>
                      {isLargeGrid ? 'Réduire le tableau' : 'Agrandir le tableau'}
                    </Button>
                  </div>
                  <div className="mui_icon_header button_wrap">
                    {from === 'fromCooperative' && (
                      <Button onClick={() => setAddCultureModal(!addCultureModal)}>
                        Ajouter <AddIcon />
                      </Button>
                    )}
                    {from === 'fromSector' ||
                      (from === 'fromExploitation' && (
                        <Button
                          onClick={() => {
                            setOpenResetDialog(true);
                          }}
                        >
                          Remettre les valeurs par défaut
                        </Button>
                      ))}
                  </div>
                </Box>
              </ThemeProvider>
            </div>
          </div>
          <div className="datagrid_container">
            <div
              style={{
                width: '100%',
                margin: 'auto',
              }}
            >
              <CustomDataGrid
                rows={rows}
                fromCultures
                columns={processedColumns}
                sortModel={sortModel}
                setSortModel={setSortModel}
                onRowSelectionModelChange={setSelectedRows}
                rowSelectionModel={selectedRows}
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                loading={dataGridLoading}
                columnVisibilityModel={columnVisible}
                toggleVisibilityFilter={toggleVisibilityFilter}
                setColumnVisibilityModel={setColumnVisible}
                processRowUpdate={processRowUpdate}
                isLargeGrid={isLargeGrid}
                disableColumnResize={true}
                isEditCoProduct={isEditCoProduct}
                isEditInputCost={isEditInputCost}
                isEditableIFT={isEditableIFT}
              />
            </div>
          </div>
        </div>
        {renderDeleteDialog()}
        {renderDuplicateDialog()}
        {renderDeleteEffectsDialog()}
        {renderResetDialog(openResetDialog, setOpenResetDialog, resetAllCultures)}
        {openInputCostDialog &&
          (nitrogenPriceValue?.nitrogenPrice !== 0 ? (
            <InputCostModalNitrogen
              openInputCostDialog={openInputCostDialog}
              setOpenInputCostDialog={setOpenInputCostDialog}
              inputCostValue={inputCostValue}
              refreshData={refreshData}
              paramsInputCost={paramsInputCost}
              setParamsInputCost={setParamsInputCost}
              errorsFormDefault={errorsFormDefault}
              setErrorsFormDefault={setErrorsFormDefault}
              columns={processedColumns}
              owner={
                from !== 'fromCooperative'
                  ? { parent: from, id: sector ? sector.id : exploitation.id }
                  : null
              }
              updatedRow={rows.find((row) => row.id === inputCostValue.cultureId)}
              nitrogenPriceValue={nitrogenPriceValue}
            />
          ) : (
            <InputCostModal
              openInputCostDialog={openInputCostDialog}
              setOpenInputCostDialog={setOpenInputCostDialog}
              inputCostValue={inputCostValue}
              refreshData={refreshData}
              paramsInputCost={paramsInputCost}
              setParamsInputCost={setParamsInputCost}
              errorsFormDefault={errorsFormDefault}
              setErrorsFormDefault={setErrorsFormDefault}
              columns={processedColumns}
              owner={
                from !== 'fromCooperative'
                  ? { parent: from, id: sector ? sector.id : exploitation.id }
                  : null
              }
              updatedRow={rows.find((row) => row.id === inputCostValue.cultureId)}
            />
          ))}
        {openIFTDialog && (
          <IFTModal
            openIFTDialog={openIFTDialog}
            setOpenIFTDialog={setOpenIFTDialog}
            refreshData={refreshData}
            errorsFormDefault={errorsFormDefault}
            setErrorsFormDefault={setErrorsFormDefault}
            columns={processedColumns}
            IFTValue={IFTValue}
            owner={
              from !== 'fromCooperative'
                ? { parent: from, id: sector ? sector.id : exploitation.id }
                : null
            }
            updatedRow={rows.find((row) => row.id === IFTValue.cultureId)}
          />
        )}
        {openNitrogenPriceDialog && (
          <NitrogenPriceModal
            openNitrogenPriceDialog={openNitrogenPriceDialog}
            setOpenNitrogenPriceDialog={setOpenNitrogenPriceDialog}
            errorsFormNitrogenEconomicValue={errorsFormNitrogenEconomicValue}
            setErrorsNitrogenFormEconomicValue={setErrorsNitrogenFormEconomicValue}
            nitrogenPriceValue={nitrogenPriceValue}
            owner={
              from !== 'fromCooperative'
                ? { parent: from, id: sector ? sector.id : exploitation.id }
                : null
            }
            cooperativeId={cooperativeId}
            sector={sector?.name}
            exploitation={exploitation}
            cooperativeName={cooperativeName}
            getEconomicAttribute={getEconomicAttribute}
          />
        )}
        {addCultureModal &&
          (nitrogenPriceValue?.nitrogenPrice !== 0 ? (
            <AddCultureModalNitrogen
              modal={addCultureModal}
              setModal={setAddCultureModal}
              onSubmitSuccess={formSuccess}
              role={user?.role}
              cultures={cultures}
            />
          ) : (
            <AddCultureModal
              modal={addCultureModal}
              setModal={setAddCultureModal}
              onSubmitSuccess={formSuccess}
              role={user?.role}
              cultures={cultures}
            />
          ))}
      </div>
    )
  );
};

export default DataGridCulture;
