import { useEffect, useState, useRef, SyntheticEvent } from 'react';
import { toast } from 'react-toastify';

import {
  addCulture,
  getCoProductsPerCooperative,
  getCAPCategoriesList,
} from 'services/API/Cooperative';
import {
  checkDecimalNegativeDatagrid,
  checkDecimalRegexTwoDigitsDatagridValue,
  checkEmptyFieldAndNoDecimalDatagridInteger,
  checkNoDecimalRegexTwoDigitsDatagridValue,
  checkTypeStringRegexDatagrid,
  displayErrorMessage,
  emptyFieldToZero,
} from 'utils/tools_functions';
import { SwatchesPicker } from 'react-color';
import {
  inputRequired,
  inputIntegerValidator,
  inputPositiveFloatValidator,
  inputNumberRequired,
  inputAbsolutePositiveFloatValidator,
  inputPositiveNotNullFloatValidator,
  inputExtraCostValidator,
  inputVerifyName,
  inputIrrigationYieldGain,
  inputIFTValidator,
  inputNitrogenValidator,
  inputYieldValidator,
  inputDurationValidator,
  inputCapCategoryAllowArableValidator,
} from 'components/generics/Validators/validator';
import {
  validateFormData,
  validateFormDataCapCategory,
} from 'components/generics/Validators/form.validate';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { customDialogAddCultureTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_culture';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import LoadingScreen from 'components/generics/loadingScreen';
import { generic } from 'utils/messages/generic/message.generic';
import { IEconomicAttributeCollection } from 'components/generics/Interface/IEconomicAttributeCollection';
import { ICulture } from 'components/generics/Interface/ICulture';
import { ICoproductType } from 'components/generics/Interface/ICoproduct';
import { ICapCategory } from 'components/generics/Interface/ICapCategory';
import { IFormErrorObjects, IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';

const DEFAULT_FORM = {
  name: '',
  economicAttributeCollection: {
    price: {
      currentEconomicValue: '',
      futureEconomicValue: '',
    },
    inputCostWithoutNitrogen: {
      currentEconomicValue: '',
      futureEconomicValue: '',
    },
  },
  tfiHerbicide: 0,
  tfiWithoutHerbicide: 0,
  presenceDuration: '',
  prohibitionDuration: '',
  yield: '',
  irrigationYieldGain: '',
  irrigationWaterNeeded: '',
  coProducts: [],
  isSummerCulture: -1,
  isStrawProducer: false,
  isArable: true,
  capCategory: 0,
  extraCost: 0,
  isIrrigableOnly: false,
  color: undefined,
  nitrogenBonus: 0,
  nitrogenLimit: 0,
};

type FormData = {
  name: string;
  economicAttributeCollection: IEconomicAttributeCollection;
  tfiHerbicide: number;
  tfiWithoutHerbicide: number;
  presenceDuration: string;
  prohibitionDuration: string;
  yield: string;
  irrigationYieldGain: string;
  irrigationWaterNeeded: string;
  coProducts: CoproductWithoutName[];
  isSummerCulture: number;
  isStrawProducer: boolean;
  isArable: boolean;
  capCategory: number | null;
  extraCost: number;
  isIrrigableOnly: boolean;
  color: undefined | string;
  nitrogenBonus: number;
  nitrogenLimit: number;
};

type BlankCoProduct = { id: number | string; displayName: string };

type CoproductWithoutName = {
  id: number | string;
  displayName: string;
  yield?: number | string;
};
type Color = {
  hex: string;
  [key: string]: any;
};

type AddCultureModalParams = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: Function;
  role: string;
  cultures: ICulture[];
};

const AddCultureModalNitrogen = ({
  modal,
  setModal,
  onSubmitSuccess,
  role = '',
  cultures,
}: AddCultureModalParams) => {
  const [formData, setFormData] = useState<FormData>(DEFAULT_FORM);
  const [coProductsTypes, setCoProductsTypes] = useState<ICoproductType[]>([]);
  const [capCategories, setCAPCategories] = useState<ICapCategory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [errorsFormCoProduct, setErrorsFormCoProduct] = useState<IFormErrors>({
    formError: false,
    exception: true,
    yield: {
      message: '',
      validator: [inputNumberRequired, inputAbsolutePositiveFloatValidator],
    },
  });
  const [errorsFormCapCategory, setErrorsFormCapCategory] = useState({
    formError: false,
    capCategory: {
      message: '',
      validator: [inputCapCategoryAllowArableValidator],
    },
  });
  const [errorsFormEconomicValue, setErrorsFormEconomicValue] = useState({
    formError: false,
    price: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    priceN5: {
      message: '',
      validator: [inputPositiveFloatValidator],
    },
    inputCostWithoutNitrogen: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    inputCostWithoutNitrogenN5: {
      message: '',
      validator: [inputPositiveFloatValidator],
    },
  });
  const [errorCoProductsMessages, setErrorCoProductsMessages] = useState<string[]>([]);
  const [errorsFormDefault, setErrorsFormDefault] = useState<IFormErrors>({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired, inputVerifyName],
    },
    extraCost: {
      message: '',
      validator: [inputExtraCostValidator],
    },
    tfiHerbicide: {
      message: '',
      validator: [inputPositiveNotNullFloatValidator, inputIFTValidator],
    },
    tfiWithoutHerbicide: {
      message: '',
      validator: [inputPositiveNotNullFloatValidator, inputIFTValidator],
    },
    presenceDuration: {
      message: '',
      validator: [inputNumberRequired, inputIntegerValidator, inputDurationValidator],
    },
    prohibitionDuration: {
      message: '',
      validator: [inputNumberRequired, inputIntegerValidator, inputDurationValidator],
    },
    yield: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator, inputYieldValidator],
    },
    irrigationYieldGain: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator, inputIrrigationYieldGain],
    },
    irrigationWaterNeeded: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
    nitrogenBonus: {
      message: '',
      validator: [inputIntegerValidator, inputNitrogenValidator],
    },
    nitrogenLimit: {
      message: '',
      validator: [inputIntegerValidator, inputNitrogenValidator],
    },
  });
  const [saisonCultures] = useState([
    { value: -1, label: 'Aucune' },
    { value: 0, label: "Culture d'hiver" },
    { value: 1, label: "Culture d'été" },
  ]);
  const blankCoProduct = {
    id: 0,
    displayName: 'Aucun',
  };
  const [coProductState, setCoProductState] = useState<BlankCoProduct[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLoading(true);
    let cooperativeId = null;
    if (role && role === 'SUPER_ADMIN') cooperativeId = Number(localStorage.getItem('cooperative'));
    getCoProductsPerCooperative(cooperativeId)
      .then((e) => {
        setCoProductsTypes([{ id: 0, displayName: 'Aucun' }].concat(e.data.data));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });

    getCAPCategoriesList().then((e) => {
      setCAPCategories([{ id: 0, displayName: 'Aucun' }].concat(e.data.data));
      setLoading(false);
    });
  }, []);

  const addCoProduct = () => {
    setCoProductState((prevCoProductState) => [...prevCoProductState, blankCoProduct]);
    return <></>;
  };

  const lastCoProduct: CoproductWithoutName = formData.coProducts.slice(-1)[0];
  const lastCoProductState: CoproductWithoutName = coProductState.slice(-1)[0];

  const checkDisabledCoProductButton =
    formData.coProducts && Object.keys(formData.coProducts).length
      ? !lastCoProduct.id || !lastCoProduct.yield || !lastCoProductState.id
        ? true
        : false
      : true;

  const checkDisabledCoProduct = (index: number) => {
    if (coProductState && coProductState[index + 1]) {
      return true;
    }
    return false;
  };

  const processedCoProductTypes = (idx: number) => {
    if (idx >= 1) {
      const previousCoProducts = formData.coProducts.slice(0, idx);

      return coProductsTypes.filter(
        (coProductType) =>
          !previousCoProducts.some((previousCoProduct) => previousCoProduct.id === coProductType.id)
      );
    }

    return coProductsTypes;
  };

  const removeCoProduct = (key: number) => {
    if (formData.coProducts && Object.keys(formData.coProducts).length === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coProducts: [blankCoProduct],
      }));
    } else {
      if (!Object.keys(formData.coProducts).length) {
        return;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        coProducts: prevFormData.coProducts.filter((_, idx) => idx !== key),
      }));
    }
    setCoProductState((prevCoProductState) => prevCoProductState.filter((_, idx) => idx !== key));
  };

  const updateCoProduct = (key: number, updatedCoProduct: CoproductWithoutName) => {
    setCoProductState((prevCoProductState) =>
      prevCoProductState.map((prevCoProductStateItem, index) =>
        index === key
          ? {
              ...prevCoProductStateItem,
              id: updatedCoProduct.id,
              displayName: updatedCoProduct.displayName,
            }
          : prevCoProductStateItem
      )
    );
  };

  const handleClick = () => {
    setDisplayColorPicker(true);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const checkUniqueColor = (newColor: Color) => {
    const existingColorCulture = cultures?.find((culture) => culture.color === newColor?.hex);
    if (existingColorCulture) {
      toast.error(
        `Cette couleur est déjà attribuée à la culture "${existingColorCulture?.name}". Veuillez en sélectionner une autre.`
      );
      return false;
    }
    return true;
  };

  const copyCurrentValueToFuture = () => {
    for (const key in formData?.economicAttributeCollection) {
      const economicType =
        formData.economicAttributeCollection[
          key as keyof typeof formData.economicAttributeCollection
        ];
      if (
        !economicType.futureEconomicValue &&
        economicType.futureEconomicValue != '0' &&
        economicType.currentEconomicValue
      ) {
        economicType.futureEconomicValue = economicType.currentEconomicValue;
      }
    }
  };

  const onSubmit = (e: SyntheticEvent) => {
    if (e) e.preventDefault();
    copyCurrentValueToFuture();
    errorsFormEconomicValue.formError = false;
    const economicAttributeCollectionCopy: { [key: string]: any } = {};
    for (const key in formData?.economicAttributeCollection) {
      const economicType =
        formData.economicAttributeCollection[
          key as keyof typeof formData.economicAttributeCollection
        ];
      for (const subKey in economicType) {
        if (subKey === 'currentEconomicValue') {
          economicAttributeCollectionCopy[key] =
            formData.economicAttributeCollection[key as keyof IEconomicAttributeCollection][subKey];
        } else if (subKey === 'futureEconomicValue') {
          economicAttributeCollectionCopy[key + 'N5'] =
            formData.economicAttributeCollection[key as keyof IEconomicAttributeCollection][subKey];
        }
      }
    }

    setErrorsFormEconomicValue({
      ...validateFormData(economicAttributeCollectionCopy, errorsFormEconomicValue),
    });
    errorsFormDefault.formError = false;
    errorsFormCoProduct.formError = false;
    errorsFormCapCategory.formError = false;
    setErrorCoProductsMessages([]);
    setErrorsFormDefault({ ...validateFormData(formData, errorsFormDefault) });
    setErrorsFormCapCategory({
      ...validateFormDataCapCategory(
        formData,
        errorsFormCapCategory,
        capCategories,
        formData.isArable
      ),
    });
    let coProductErrorMessagesArray = [];
    for (const key in formData?.coProducts) {
      const validateCoProduct = validateFormData(
        formData.coProducts[key],
        errorsFormCoProduct,
        key
      );
      if (validateCoProduct.yield.message) {
        coProductErrorMessagesArray.push(validateCoProduct.yield.message);
      }
      setErrorsFormCoProduct({
        ...validateCoProduct,
      });
    }
    setErrorCoProductsMessages(coProductErrorMessagesArray);
    if (
      !errorsFormDefault.formError &&
      !coProductErrorMessagesArray.length &&
      !errorsFormEconomicValue.formError &&
      !errorsFormCapCategory.formError
    ) {
      setPostLoading(true);
      const formDataCopy = { ...formData };
      formDataCopy.coProducts = formData.coProducts.filter((coProduct) => coProduct.id !== 0);
      formDataCopy.capCategory = formData.capCategory === 0 ? null : formData.capCategory;

      let cooperativeId = -1;
      if (role && role === 'SUPER_ADMIN')
        cooperativeId = Number(localStorage.getItem('cooperative'));
      addCulture(formDataCopy, cooperativeId)
        .then(() => {
          toast.success(`Culture ${formData.name} créée`);
          setFormData(DEFAULT_FORM);
          setPostLoading(false);
          onSubmitSuccess();
        })
        .catch((err) => {
          setPostLoading(false);
          if (
            err.response.data['message'] ===
            "A culture can't be arable with a CapCategory that doesn't allow arable"
          ) {
            toast.error(displayErrorMessage('ERR_ADD_CULTURE_CAP_CATEGORY'));
          } else {
            toast.error(err.response.data['message']);
          }
        });
    }
  };

  const updateEconomicAttribute = (key: string, value: number, subkey: string) => {
    setFormData((prevData) => {
      const updatedAttribute: IEconomicAttributeCollection = {
        ...prevData.economicAttributeCollection,
      };
      updatedAttribute[key as keyof IEconomicAttributeCollection] = {
        ...updatedAttribute[key as keyof IEconomicAttributeCollection],
        [subkey]: value,
      };
      return {
        ...prevData,
        economicAttributeCollection: updatedAttribute,
      };
    });
  };

  const updateForm = (
    key: string,
    value: number | [number, number, ICoproductType[]] | [number, string] | boolean | string | null,
    subkey?: string
  ) => {
    let tab = formData.coProducts ? [...formData.coProducts] : [];
    if (key === 'coProducts' && Array.isArray(value) && value.length === 3) {
      const id = value[0];
      const newValue = value[1];
      const displayName = value[2]?.filter(
        (coProduct: ICoproductType) => coProduct.id === newValue
      )[0].displayName;
      const newCoProduct = {
        id: newValue,
        displayName,
        yield: formData?.coProducts?.[id]?.yield ?? '',
      };
      if (formData.coProducts && formData.coProducts[id]) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          coProducts: prevFormData.coProducts.map((prevCoProduct, index) =>
            id === index ? newCoProduct : prevCoProduct
          ),
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          coProducts: prevFormData.coProducts
            ? [...prevFormData.coProducts, newCoProduct]
            : [newCoProduct],
        }));
      }
      updateCoProduct(id, newCoProduct);
    } else if (key === 'yieldProduct' && Array.isArray(value) && value.length == 2) {
      const id = value[0];
      const newValue = value[1];
      if (tab === null) {
        tab = [];
      }
      tab[id] ? (tab[id].yield = newValue) : tab.push({ id: newValue, displayName: '' });
      setFormData({ ...formData });
    } else if (key === 'tfiHerbicide' || key === 'tfiWithoutHerbicide' || key === 'extraCost') {
      setFormData({ ...formData, [key]: emptyFieldToZero(value) });
    } else if (key === 'price' || key === 'inputCostWithoutNitrogen') {
      if (subkey) {
        updateEconomicAttribute(key, Number(value), subkey);
      }
    } else {
      setFormData({ ...formData, [key]: value });
    }
  };

  return (
    <>
      <ThemeProvider theme={customDialogAddCultureTheme}>
        <Dialog
          maxWidth="md"
          fullWidth
          open={modal}
          ref={ref}
          PaperProps={{ ref: ref }}
          onClose={() => setModal(false)}
        >
          <div className="add_culture_container">
            <LoadingScreen open={loading}></LoadingScreen>
            <LoadingScreen
              open={postLoading}
              text={generic?.CREATE_CULTURE_LOADER?.fr}
            ></LoadingScreen>
            <Box className="modal_header">
              <DialogTitle>Ajouter une culture</DialogTitle>
              <ClearIcon className="close-icon" onClick={() => setModal(false)} />
            </Box>
            <DialogContent>
              <Box id="add_name">
                <InputLabel id="nameLabel" htmlFor="name">
                  Nom<span className="label_asterisk">*</span>
                </InputLabel>
                <TextField
                  id="name"
                  value={formData.name}
                  onChange={(e) => {
                    updateForm('name', checkTypeStringRegexDatagrid(e.target.value));
                  }}
                  placeholder="Entrez un nom"
                  disabled={loading}
                  error={
                    (errorsFormDefault?.['name' as keyof IFormErrors] as IFormErrorObjects).message
                      ? true
                      : false
                  }
                  helperText={
                    (errorsFormDefault?.['name' as keyof IFormErrors] as IFormErrorObjects).message
                  }
                />
              </Box>
              <fieldset className="add_culture_fieldset">
                <legend>Prix</legend>
                <Box>
                  <InputLabel id="priceLabel" htmlFor="price">
                    Prix de vente (€/t)<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="price"
                    value={formData.economicAttributeCollection?.price?.currentEconomicValue}
                    onChange={(e) => {
                      updateForm(
                        'price',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value),
                        'currentEconomicValue'
                      );
                    }}
                    placeholder="Entrez le prix de vente"
                    disabled={loading}
                    error={errorsFormEconomicValue?.price.message ? true : false}
                    helperText={errorsFormEconomicValue?.price.message}
                  />
                </Box>
                <Box>
                  <InputLabel id="inputCostWithoutNitrogenLabel" htmlFor="inputCostWithoutNitrogen">
                    Coût intrant hors Azote (€/ha)<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="inputCostWithoutNitrogen"
                    value={
                      formData.economicAttributeCollection?.inputCostWithoutNitrogen
                        ?.currentEconomicValue
                    }
                    onChange={(e) => {
                      updateForm(
                        'inputCostWithoutNitrogen',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value),
                        'currentEconomicValue'
                      );
                    }}
                    placeholder="Entrez un coût intrant hors Azote"
                    disabled={loading}
                    error={errorsFormEconomicValue?.inputCostWithoutNitrogen.message ? true : false}
                    helperText={errorsFormEconomicValue?.inputCostWithoutNitrogen.message}
                  />
                </Box>
                <Box>
                  <InputLabel id="extraCostLabel" htmlFor="extraCost">
                    Charges extra (€/ha)
                  </InputLabel>
                  <TextField
                    id="extraCost"
                    value={formData.extraCost}
                    onChange={(e) => {
                      updateForm('extraCost', checkDecimalNegativeDatagrid(e.target.value));
                    }}
                    placeholder="Entrez un coût extra"
                    disabled={loading}
                    error={
                      (errorsFormDefault?.['extraCost' as keyof IFormErrors] as IFormErrorObjects)
                        .message
                        ? true
                        : false
                    }
                    helperText={
                      (errorsFormDefault?.['extraCost' as keyof IFormErrors] as IFormErrorObjects)
                        .message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="priceN5Label" htmlFor="priceN5">
                    Prix de vente N+5 (€/t) <sup style={{ letterSpacing: '0.5px' }}>(1)</sup>
                  </InputLabel>

                  <TextField
                    id="priceN5"
                    value={formData.economicAttributeCollection?.price?.futureEconomicValue}
                    onChange={(e) => {
                      updateForm(
                        'price',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value),
                        'futureEconomicValue'
                      );
                    }}
                    placeholder="Entrez le prix de vente à N+5"
                    disabled={loading}
                    error={errorsFormEconomicValue?.priceN5.message ? true : false}
                    helperText={errorsFormEconomicValue?.priceN5.message}
                  />
                </Box>
                <Box>
                  <InputLabel
                    id="inputCostWithoutNitrogenN5Label"
                    htmlFor="inputCostWithoutNitrogenN5"
                  >
                    Coût intrant hors Azote N+5 (€/ha)
                    <sup style={{ letterSpacing: '0.5px' }}>(1)</sup>
                  </InputLabel>
                  <TextField
                    id="inputCostWithoutNitrogenN5"
                    value={
                      formData.economicAttributeCollection?.inputCostWithoutNitrogen
                        ?.futureEconomicValue
                    }
                    onChange={(e) => {
                      updateForm(
                        'inputCostWithoutNitrogen',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value),
                        'futureEconomicValue'
                      );
                    }}
                    placeholder="Entrez un coût intrant hors Azote N+5"
                    disabled={loading}
                    error={
                      errorsFormEconomicValue?.inputCostWithoutNitrogenN5.message ? true : false
                    }
                    helperText={errorsFormEconomicValue?.inputCostWithoutNitrogenN5.message}
                  />
                </Box>
                <Box>
                  <InputLabel id="nitrogenBonusLabel" htmlFor="nitrogenBonus">
                    Bonus d'azote apportée (unité/ha)
                  </InputLabel>
                  <TextField
                    id="nitrogenBonus"
                    value={formData.nitrogenBonus}
                    onChange={(e) => {
                      updateForm(
                        'nitrogenBonus',
                        checkEmptyFieldAndNoDecimalDatagridInteger(e.target.value)
                      );
                    }}
                    placeholder="Entrez le bonus d'azote apportée"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'nitrogenBonus' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'nitrogenBonus' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="nitrogenLimitLabel" htmlFor="nitrogenLimit">
                    Quantité d'azote maximale absorbable (unité/ha)
                  </InputLabel>
                  <TextField
                    id="nitrogenLimit"
                    value={formData.nitrogenLimit}
                    onChange={(e) => {
                      updateForm(
                        'nitrogenLimit',
                        checkEmptyFieldAndNoDecimalDatagridInteger(e.target.value)
                      );
                    }}
                    placeholder="Entrez la quantité d'azote maximale absorbable"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'nitrogenLimit' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'nitrogenLimit' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
              </fieldset>
              <fieldset className="add_culture_fieldset">
                <legend>Rendement</legend>
                <Box>
                  <InputLabel id="tfiLabelHerbicide" htmlFor="tfiHerbicide">
                    IFT Herbicides
                  </InputLabel>
                  <TextField
                    id="iftHerbicide"
                    value={formData.tfiHerbicide}
                    onChange={(e) => {
                      updateForm(
                        'tfiHerbicide',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value)
                      );
                    }}
                    placeholder="Entrez l'IFT herbicides"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'tfiHerbicide' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'tfiHerbicide' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="tfiLabelWithoutHerbicide" htmlFor="tfiWithoutHerbicide">
                    IFT Hors Herbicides
                  </InputLabel>
                  <TextField
                    id="tfiWithoutHerbicide"
                    value={formData.tfiWithoutHerbicide}
                    onChange={(e) => {
                      updateForm(
                        'tfiWithoutHerbicide',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value)
                      );
                    }}
                    placeholder="Entrez l'IFT hors herbicides"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'tfiWithoutHerbicide' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'tfiWithoutHerbicide' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="presenceDurationLabel" htmlFor="presenceDuration">
                    Durée de présence (an)<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="presenceDuration"
                    value={formData.presenceDuration}
                    onChange={(e) => {
                      updateForm(
                        'presenceDuration',
                        checkNoDecimalRegexTwoDigitsDatagridValue(e.target.value)
                      );
                    }}
                    placeholder="Entrez la durée de présence"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'presenceDuration' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'presenceDuration' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="prohibitionDurationLabel" htmlFor="prohibitionDuration">
                    Durée d'interdiction (an)<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="prohibitionDuration"
                    value={formData.prohibitionDuration}
                    onChange={(e) => {
                      updateForm(
                        'prohibitionDuration',
                        checkNoDecimalRegexTwoDigitsDatagridValue(e.target.value)
                      );
                    }}
                    placeholder="Entrez la durée d'interdiction"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'prohibitionDuration' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'prohibitionDuration' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="yieldLabel" htmlFor="yield">
                    Rendement (t/ha)<span className="label_asterisk">*</span>
                  </InputLabel>

                  <TextField
                    id="yield"
                    value={formData.yield}
                    onChange={(e) => {
                      updateForm('yield', checkDecimalRegexTwoDigitsDatagridValue(e.target.value));
                    }}
                    placeholder="Entrez le rendement"
                    disabled={loading}
                    error={
                      (errorsFormDefault?.['yield' as keyof IFormErrors] as IFormErrorObjects)
                        .message
                        ? true
                        : false
                    }
                    helperText={
                      (errorsFormDefault?.['yield' as keyof IFormErrors] as IFormErrorObjects)
                        .message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel id="irrigationYieldGainLabel" htmlFor="irrigationYieldGain">
                    Gain rendement si irrigué (%)<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="irrigationYieldGain"
                    value={formData.irrigationYieldGain}
                    onChange={(e) => {
                      updateForm(
                        'irrigationYieldGain',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value)
                      );
                    }}
                    placeholder="Entrez le gain de rendement si irriguée"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'irrigationYieldGain' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'irrigationYieldGain' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
              </fieldset>
              <div className="add_culture_select">
                <Box className="irrigationWaterNeededBox">
                  <InputLabel id="irrigationWaterNeededLabel" htmlFor="irrigationWaterNeeded">
                    Consommation eau si irrigué (mm/ha)<span className="label_asterisk">*</span>
                  </InputLabel>

                  <TextField
                    id="irrigationWaterNeeded"
                    value={formData.irrigationWaterNeeded}
                    onChange={(e) => {
                      updateForm(
                        'irrigationWaterNeeded',
                        checkDecimalRegexTwoDigitsDatagridValue(e.target.value)
                      );
                    }}
                    placeholder="Entrez la quantité d'eau nécessaire par hectare si irrigué"
                    disabled={loading}
                    error={
                      (
                        errorsFormDefault?.[
                          'irrigationWaterNeeded' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? true
                        : false
                    }
                    helperText={
                      (
                        errorsFormDefault?.[
                          'irrigationWaterNeeded' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                    }
                  />
                </Box>
                <Box>
                  <InputLabel htmlFor="isSummerCulture">Culture d'été :</InputLabel>
                  <Select
                    id="isSummerCulture"
                    sx={{ width: '300px' }}
                    onChange={(e) => updateForm('isSummerCulture', e.target.value)}
                    value={formData.isSummerCulture}
                  >
                    {saisonCultures.map((seasonCulture) => (
                      <MenuItem key={seasonCulture?.label} value={seasonCulture?.value}>
                        <ListItemText primary={seasonCulture.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {capCategories.length && (
                  <Box>
                    <InputLabel htmlFor="capCategory">Catégorie PAC :</InputLabel>
                    <Select
                      id="capCategory"
                      sx={{ width: '300px' }}
                      onChange={(e) => updateForm('capCategory', e.target.value)}
                      value={formData.capCategory}
                      error={errorsFormCapCategory?.capCategory.message ? true : false}
                    >
                      {capCategories.map((capCategory) => (
                        <MenuItem key={capCategory.id} value={capCategory.id}>
                          <ListItemText primary={capCategory.displayName} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ left: '15%' }}>
                      {errorsFormCapCategory?.capCategory.message}
                    </FormHelperText>
                  </Box>
                )}
              </div>
              <fieldset className="add_culture_fieldset">
                <legend>
                  Co-produit{' '}
                  <ThemeProvider theme={customButtonTheme}>
                    <Button
                      id="add_coProduct_button"
                      onClick={addCoProduct}
                      disabled={checkDisabledCoProductButton}
                    >
                      Ajouter
                    </Button>
                  </ThemeProvider>
                </legend>
                <Box id="add_culture_coProducts_container">
                  {coProductState.length ? null : addCoProduct()}
                  {coProductsTypes.length &&
                    coProductState?.map((coProductStateItem, idx) => {
                      const coProductSelectId = `selectId-${idx}`;
                      const coProductSelectName = `coProductsType-${idx}`;
                      const yieldProductId = `yield-${idx}`;
                      const displayName = formData?.coProducts?.[idx]?.displayName;
                      return (
                        <section
                          className="add_culture_coProducts_section"
                          key={`coProduct-${idx}`}
                        >
                          <Box id="coproduct_box">
                            <InputLabel id="coProductTypeLabel" htmlFor={coProductSelectId}>
                              Type de co-produit :
                            </InputLabel>
                            <Select
                              id={coProductSelectId}
                              onChange={(e) =>
                                updateForm('coProducts', [
                                  idx,
                                  Number(e.target.value),
                                  coProductsTypes,
                                ])
                              }
                              value={
                                formData?.coProducts
                                  ? formData?.coProducts[idx]
                                    ? formData?.coProducts[idx].id
                                    : 0
                                  : 0
                              }
                              disabled={checkDisabledCoProduct(idx)}
                              className={errorCoProductsMessages.length ? 'MuiErrorForm' : ''}
                            >
                              {processedCoProductTypes(idx).map((coProductsType) => (
                                <MenuItem key={coProductsType?.id} value={coProductsType?.id}>
                                  <ListItemText primary={coProductsType.displayName} />
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <Box id="coproduct_box">
                            <InputLabel id="coProductYieldLabel" htmlFor={yieldProductId}>
                              {`Rendement co-produit ${
                                displayName ? displayName : ''
                              } (% rendement grain)`}
                            </InputLabel>
                            <TextField
                              id={yieldProductId}
                              name={yieldProductId}
                              value={
                                formData?.coProducts
                                  ? formData?.coProducts[idx]
                                    ? formData?.coProducts[idx].yield ?? ''
                                    : ''
                                  : ''
                              }
                              onChange={(e) => {
                                updateForm('yieldProduct', [
                                  idx,
                                  checkDecimalNegativeDatagrid(e.target.value),
                                ]);
                              }}
                              placeholder="Entrez le rendement co-produit (% rendement grain)"
                              disabled={
                                !formData?.coProducts[idx]?.id
                                  ? true
                                  : checkDisabledCoProduct(idx)
                                  ? true
                                  : loading
                              }
                              error={
                                errorCoProductsMessages &&
                                errorCoProductsMessages?.[0]?.includes(
                                  coProductStateItem.displayName
                                )
                                  ? true
                                  : false
                              }
                              helperText={
                                errorCoProductsMessages &&
                                errorCoProductsMessages?.[0]?.includes(
                                  coProductStateItem.displayName
                                ) &&
                                errorCoProductsMessages[0]
                              }
                            />
                            <DeleteIcon
                              className="coproduct_delete"
                              onClick={(e) => {
                                removeCoProduct(idx);
                              }}
                            />
                          </Box>
                        </section>
                      );
                    })}
                </Box>
              </fieldset>

              <div
                className="add_culture_checkbox"
                style={{ marginTop: !formData?.color ? '47px' : 0 }}
              >
                <Box className="colorContainer">
                  <ThemeProvider theme={customButtonTheme}>
                    {!formData?.color ? (
                      <Button onClick={handleClick}>Ajouter une couleur</Button>
                    ) : (
                      <>
                        <div className="colorBlockContainer">
                          <InputLabel className="color_label">Couleur :</InputLabel>

                          <div className="colorBlock colorBlock__modal">
                            <div
                              className="colorStyle"
                              style={{
                                background: `${formData.color ?? DEFAULT_FORM.color}`,
                              }}
                              onClick={handleClick}
                            />
                            <Button
                              onClick={() => {
                                updateForm('color', null);
                                handleClose();
                              }}
                              id="colorBlock__button"
                            >
                              Supprimer
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {displayColorPicker ? (
                      <div className="colorPicker">
                        <div className="colorPickerCover" onClick={handleClose} />
                        <SwatchesPicker
                          className="colorPickerPosition"
                          width={500}
                          height={430}
                          color={formData.color ?? DEFAULT_FORM.color}
                          onChangeComplete={(newColor: Color) => {
                            const isUniqueColor = checkUniqueColor(newColor);
                            if (isUniqueColor) {
                              updateForm('color', newColor?.hex);
                              handleClose();
                            }
                          }}
                        />
                      </div>
                    ) : null}
                  </ThemeProvider>
                </Box>
                <Box>
                  <InputLabel htmlFor="isStrawProducer">Céréale à paille :</InputLabel>
                  <Checkbox
                    id="isStrawProducer"
                    checked={formData.isStrawProducer}
                    onChange={() => {
                      updateForm('isStrawProducer', !formData.isStrawProducer);
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel id="isIrrigableLabel" htmlFor="isIrrigableOnly">
                    Irrigation obligatoire :
                  </InputLabel>
                  <Checkbox
                    id="isIrrigableOnly"
                    checked={formData.isIrrigableOnly}
                    onChange={() => {
                      if (!formData.isIrrigableOnly) {
                        setErrorsFormDefault({
                          ...errorsFormDefault,
                          irrigationWaterNeeded: {
                            ...(errorsFormDefault?.irrigationWaterNeeded as IFormErrorObjects),
                            validator: [
                              ...(errorsFormDefault?.irrigationWaterNeeded as IFormErrorObjects)
                                ?.validator,
                              inputAbsolutePositiveFloatValidator,
                            ],
                          },
                        });
                      } else {
                        setErrorsFormDefault({
                          ...errorsFormDefault,
                          irrigationWaterNeeded: {
                            ...(errorsFormDefault?.irrigationWaterNeeded as IFormErrorObjects),
                            validator: [inputNumberRequired, inputPositiveFloatValidator],
                          },
                        });
                      }
                      updateForm('isIrrigableOnly', !formData.isIrrigableOnly);
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel htmlFor="isArable">Terre arable : </InputLabel>
                  <Checkbox
                    id="isArable"
                    checked={formData?.isArable}
                    onChange={() => updateForm('isArable', !formData.isArable)}
                    inputProps={{ 'aria-label': 'isArable' }}
                  />
                </Box>
              </div>
            </DialogContent>
            <div className="text_helper_culture">
              <div>
                <span className="label_asterisk">*</span> Le champ est obligatoire.
              </div>
              <div>
                (1) Si les valeurs N+5 ne sont pas renseignées, les valeurs seront automatiquement
                celles du prix/charges actuel.
              </div>
            </div>

            <DialogActions>
              <ThemeProvider theme={customButtonTheme}>
                <Button id="add_culture_button" onClick={onSubmit}>
                  Ajouter
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AddCultureModalNitrogen;
