import { createTheme } from '@mui/material';

export const customCommonDataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
            borderBottom: 'unset',
            lineHeight: 1.4,
            borderColor: '#e0e0e0',
          },
          '& .Mui-odd .MuiDataGrid-cell--pinnedLeft, .Mui-odd .MuiDataGrid-cell--pinnedRight': {
            background: '#f0f0f0',
          },
          '& .Mui-even.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft, .Mui-even.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight':
            {
              background: '#fff !important',
            },
          '& .Mui-odd.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft, .Mui-odd.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight':
            {
              background: '#f0f0f0 !important',
            },
        },
        columnHeader: {
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            '& .MuiDataGrid-iconButtonContainer': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});
