import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAuth from 'providers/Auth/useAuth';

import { sendEvent } from 'utils/Event';

import { getCooperative, getCultures } from 'services/API/Cooperative';
import StructureSectors from 'components/pages/Cooperative/StructureSectors';
import CooperativeGroundTypes from 'components/pages/Cooperative/GroundTypes';

import 'assets/styles/modal.style.scss';
import 'assets/styles/section.style.scss';
import CooperativeCsvImport from './CsvImport';
import ConstraintCoProducts from './ConstraintsCoProducts';
import CooperativeImport from './Import';
import LoginWiuz from './LoginWiuz';
import CooperativeExploitation from './Exploitation';
import Result from './Result';
import Geofolia from './Geofolia';
import GeofoliaProcess from './GeofoliaProcess';
import CulturesDataGridCooperative from './CulturesDataGridCooperative';
import RotateEffectsDataGridCooperative from './RotateEffectsDataGridCooperative';
import GroundTypeEffectsStructure from './GroundTypeEffectsStructure';
import StructureTechnicians from './StructureTechnicians';

const Cooperative = () => {
  const [cooperative, setCooperative] = useState(null);
  const [cooperativeCultures, setCooperativeCultures] = useState(null);
  const [economicAttribute, setEconomicAttribute] = useState({});
  const [nitrogenCover, setNitrogenCover] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  const cooperativeIdLocalStorate = localStorage.getItem('cooperative');
  const cooperativeId = cooperativeIdLocalStorate ? cooperativeIdLocalStorate : user.cooperative.id;

  const loadData = async () => {
    try {
      const results = await Promise.all([
        getCooperative(cooperativeId),
        getCultures(cooperativeId),
      ]);
      setCooperative(results[0].data.data);
      setEconomicAttribute(results[0].data?.data?.economicAttributeCollection);
      setNitrogenCover(results[0].data?.data?.nitrogenSupplyByCover);
      setCooperativeCultures(results[1].data.data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      toast.error(err.data);
    }
  };

  useEffect(() => {
    user.cooperative && loadData();
  }, [user]);

  return (
    <div className="Cooperative">
      <Routes>
        <Route exact path="/" element={<Navigate to="technicians" />} />
        <Route
          path="sectors"
          element={<StructureSectors sectors={cooperative?.sectors} refreshData={loadData} />}
        />
        <Route
          exact
          path="technicians"
          element={
            <StructureTechnicians
              sectors={cooperative?.sectors.map((sector) => ({
                id: sector.id,
                name: sector.name,
              }))}
              technicians={cooperative?.users.filter((item) => item.role === 'TECHNICIAN')}
              refreshData={loadData}
            />
          }
        />
        <Route
          exact
          path="cultures"
          element={
            <CulturesDataGridCooperative
              user={user}
              cooperativeId={cooperativeId}
              cooperativeName={cooperative?.name}
              economicAttribute={economicAttribute}
              setEconomicAttribute={setEconomicAttribute}
              nitrogenCover={nitrogenCover}
              setNitrogenCover={setNitrogenCover}
            />
          }
        />
        <Route
          exact
          path="groundTypes"
          element={
            <CooperativeGroundTypes groundTypes={cooperative?.groundTypes} refreshData={loadData} />
          }
        />
        <Route
          exact
          path="rotateEffects"
          element={
            <RotateEffectsDataGridCooperative
              cultures={cooperativeCultures}
              ownerId={cooperativeId}
              refreshData={loadData}
            />
          }
        />
        <Route
          exact
          path="groundTypesEffects"
          element={
            <GroundTypeEffectsStructure
              groundTypes={cooperative?.groundTypes}
              cultures={cooperativeCultures}
              refreshData={loadData}
              ownerId={cooperativeId}
            />
          }
        />
        <Route
          exact
          path="contstaints"
          element={<ConstraintCoProducts cooperativeId={cooperative?.id} refreshData={loadData} />}
        />
        <Route exact path="import" element={<CooperativeImport />} />
        <Route
          exact
          path="import/csvImport"
          element={<CooperativeCsvImport refreshData={loadData} />}
        />
        <Route exact path="import/loginWiuz" element={<LoginWiuz />} />
        <Route exact path="import/exploitation" element={<CooperativeExploitation />} />
        <Route exact path="import/result" element={<Result />} />
        <Route exact path="import/geofolia" element={<Geofolia />} />
        <Route exact path="import/geofolia/process" element={<GeofoliaProcess />} />
        <Route element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default Cooperative;
