const regexMail =
  /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;

// Validator only number and not limit
export const inputNumberValidator = (field, nameField) => {
  if (field) {
    if (!new RegExp(/^\d{1,}$/).test(field)) {
      return `Le champ ${nameField} doit contenir des chiffres uniquement`;
    }
  }
  return null;
};

// Validator only positive integer and not limit
export const inputIntegerValidator = (field, nameField) => {
  if (field) {
    if (!Number.isInteger(+field) || Number(field) < 0) {
      return `Le champ ${nameField} doit contenir des entier positifs`;
    }
  }
  return null;
};

// Validator only positive float and not limit
export const inputPositiveFloatValidator = (field, nameField) => {
  if (field) {
    if (isNaN(field) || Number(field) < 0) {
      return `Le champ ${nameField} doit contenir des nombres positifs`;
    }
  }
  return null;
};

// Validator limit number until 500
export const inputNitrogenValidator = (field, nameField) => {
  if (field > 500) {
    return `Le champ ${nameField} doit être inférieure à 500`;
  }
  return null;
};

// Validator only greater than 0 float and not limit
export const inputAbsolutePositiveFloatValidator = (field, nameField) => {
  if (field || field === 0) {
    if (isNaN(field) || Number(field) <= 0) {
      return `Le champ ${nameField} doit contenir un nombre supérieur à 0`;
    }
  }
  return null;
};

export const inputPositiveNotNullFloatValidator = (field, nameField) => {
  if ((!field && field !== 0) || isNaN(field) || Number(field) < 0) {
    return `Le champ ${nameField} doit contenir un nombre égal ou supérieur à 0`;
  }
  return null;
};

// Validator limit number until 30
export const inputIFTValidator = (field, nameField) => {
  if (field > 30) {
    return `Le champ ${nameField} doit être inférieure à 30`;
  }
  return null;
};

// Validator any float number and not limit
export const inputAnyFloatValidator = (field, nameField) => {
  if (isNaN(field)) {
    return `Le champ ${nameField} doit contenir des nombres`;
  }
  return null;
};

// Validator any float number and not limit
export const inputAnyFloatNotNullValidator = (field, nameField) => {
  if (isNaN(field) || (!field && field !== 0)) {
    return `Le champ ${nameField} doit contenir un nombre`;
  }
  return null;
};

// Validator any float number between -50000 and 50000
export const inputExtraCostValidator = (field, nameField) => {
  if (isNaN(field) || (!field && field !== 0) || field < -50000 || field > 50000) {
    return `Le champ ${nameField} doit contenir un nombre compris entre -50 000 et 50 000`;
  }
  return null;
};

// Validator any float number between 0 and 50000
export const inputYieldValidator = (field, nameField) => {
  if (field > 50000) {
    return `Le champ ${nameField} doit contenir un nombre compris entre 0 et 50 000`;
  }
  return null;
};

// Validator for presenceDuration and prohibitionDuration limit 10
export const inputDurationValidator = (field, nameField) => {
  if (nameField === 'Durée de présence') {
    if ((field && field === '0') || field > 10) {
      return `Le champ ${nameField} doit contenir un nombre compris entre 1 et 10`;
    }
  }
  if (nameField === "Durée d'interdiction" && field > 10) {
    return `Le champ ${nameField} doit contenir un nombre compris entre 0 et 10`;
  }
  return null;
};

// Validator any float number between 0 and 2000
export const inputIrrigationYieldGain = (field, nameField) => {
  if (isNaN(field) || (!field && field !== 0) || field < 0 || field > 2000) {
    return `Le champ ${nameField} doit contenir un nombre compris entre 0 et 2 000`;
  }
  return null;
};

// Validator for checking if the field is not empty
export const inputRequired = (field, nameField) => {
  if (!field) {
    return `Le champ ${nameField} est requis`;
  }
  return null;
};

// Validator for checking if the field is allowed or not
export const inputCapCategoryAllowArableValidator = (field, nameField, optionnalName) => {
  if (field === false) {
    return `${optionnalName} n'est pas autorisée avec terre arable`;
  }
  return null;
};

// Validator for checking if the field is allowed or not
export const inputAllowArableValidator = (field, nameField, optionnalName, nameInRow) => {
  if (field === false) {
    return `La Catégorie PAC ${nameInRow} n'autorise pas terre arable`;
  }
  return null;
};

// Validator for checking field name limit
export const inputVerifyName = (field, nameField) => {
  if (field.length > 0 && field.length < 3) {
    return `Le champ ${nameField} ne doit pas être inférieur à 3 caractères`;
  }
  return null;
};

// Validator for checking if the field is not empty but 0 allowed
export const inputNumberRequired = (field, nameField) => {
  if (!field && field !== 0) {
    return `Le champ ${nameField} est requis`;
  }
  return null;
};

// Validator for check mail format
export const inputVerifyMail = (field) => {
  if (field) {
    if (regexMail.test(field) === false) {
      return `Le format de l'email n'est pas correct`;
    }
  }
};

// Validator for check limit
export const inputIntegerValidatorEffect = (field, nameField) => {
  if (field) {
    if (Number(field) < 0) {
      return `Le champ ${nameField} ne doit pas être négatif`;
    }
    if (Number(field) > 500) {
      return `Le champ ${nameField} ne doit pas être supérieur à 500`;
    }
  }
  return null;
};

// Validator for rotate effects
export const inputFloatValidatorRotateEffect = (field) => {
  if (!field && field !== 0) {
    return `L'effet de rotation est requis`;
  } else if (isNaN(field)) {
    return `L'effet de rotation doit contenir des nombres uniquement`;
  } else if (field) {
    if (Number(field) < 0) {
      return `L'effet de rotation ne doit pas être négatif`;
    }
    if (Number(field) > 200) {
      return `L'effet de rotation ne doit pas être supérieur à 200`;
    }
  }
  return null;
};

// Validator for check limit years simulation
export const inputYearNumberSimulation = (field, nameField) => {
  if (field) {
    if (Number(field) < 3) {
      return `Le champ ${nameField} ne doit pas être inférieur à 3`;
    }
    if (Number(field) > 7) {
      return `Le champ ${nameField} ne doit pas être supérieur à 7`;
    }
  }
  return null;
};

export const inputStringMinLengthValidator = (min) => (field, nameField) => {
  if (field.length < min) {
    return `Le champ ${nameField} doit contenir au moins ${min} caractères`;
  }
  return null;
};


export const inputStringMinMaxLengthValidator = (min, max) => (field, nameField) => {
  if (field.length < min || field.length > max) {
    return `Le champ ${nameField} doit contenir entre ${min} et ${max} caractères`;
  }
  return null;
};