// NO REVIEW

import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAuth from 'providers/Auth/useAuth';

import { getCooperative } from 'services/API/Cooperative';
import { getSecteurInformations } from 'services/API/Secteur';

import SecteurHome from 'components/pages/Sector/Home';
import SecteurExploitations from 'components/pages/Sector/Exploitations';
import SecteurGroundTypeEffects from 'components/pages/Sector/GroundTypeEffectsDataGridSector';

import 'assets/styles/modal.style.scss';
import 'assets/styles/section.style.scss';
import CooperativeCsvImport from '../Cooperative/CsvImport';
import CooperativeImport from '../Cooperative/Import';
import LoginWiuz from '../Cooperative/LoginWiuz';
import CooperativeExploitation from '../Cooperative/Exploitation';
import Result from '../Cooperative/Result';
import Geofolia from '../Cooperative/Geofolia';
import { sendEvent } from 'utils/Event';
import { LoadingContext } from 'utils/context';
import GeofoliaProcess from '../Cooperative/GeofoliaProcess';
import CulturesDataGridSector from './CulturesDataGridSector';
import RotateEffectsDataGridSector from './RotateEffectsDataGridSector';

const Secteur = () => {
  const { user } = useAuth();
  const [secteurs, setSecteurs] = useState([]);
  const [groundTypes, setGroundTypes] = useState([]);
  const [secteurInformations, setSecteurInformations] = useState(null);
  const cooperativeId = localStorage.getItem('cooperative');
  const idGet = cooperativeId ? cooperativeId : user.cooperative.id;
  const [currentCooperative, setCurrentCooperative] = useState(null);
  const { setFullScreen } = useContext(LoadingContext);

  useEffect(() => {
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  const loadData = () => {
    if (!localStorage.getItem('sector')) {
      setFullScreen(true);
    }
    getCooperative(idGet)
      .then((resp) => {
        setCurrentCooperative(resp.data.data);
        setSecteurs(resp.data.data.sectors);
        setGroundTypes(resp.data.data.groundTypes);
      })
      .catch((err) => toast.error(err.data))
      .finally(() => setFullScreen(false));
  };

  const loadDataSector = (id) => {
    return new Promise((resolve, reject) => {
      getSecteurInformations(id)
        .then((resp) => {
          setSecteurInformations(resp.data.data);
          toast.success(`${resp.data.data.name} sélectionné`);
          resolve(resp);
        })
        .catch((err) => {
          toast.error(err.data);
        });
    });
  };

  useEffect(() => {
    user.cooperative && loadData();
  }, [user]);

  return (
    <div className="Sector">
      <Routes>
        <Route
          path="/"
          element={
            <SecteurHome
              secteurs={secteurs}
              setSecteur={loadDataSector}
              secteur={secteurInformations}
              cooperative={currentCooperative}
            />
          }
        />
        <Route
          path="exploitations"
          element={<SecteurExploitations secteur={secteurInformations} user={user} />}
        />
        <Route
          path="cultures"
          element={<CulturesDataGridSector secteur={secteurInformations} user={user} />}
        />
        <Route
          path="groundTypesEffects"
          element={
            <SecteurGroundTypeEffects secteur={secteurInformations} groundTypes={groundTypes} />
          }
        />
        <Route
          path="rotateEffects"
          element={<RotateEffectsDataGridSector secteur={secteurInformations} />}
        />
        <Route path="import" element={<CooperativeImport />} />
        <Route path="import/csvImport" element={<CooperativeCsvImport />} />
        <Route path="import/loginWiuz" element={<LoginWiuz />} />
        <Route path="import/exploitation" element={<CooperativeExploitation />} />
        <Route path="import/result" element={<Result />} />
        <Route path="import/geofolia" element={<Geofolia />} />
        <Route exact path="import/geofolia/process" element={<GeofoliaProcess />} />
        <Route element={<Navigate to="/exploitation" />} />
      </Routes>
    </div>
  );
};

export default Secteur;
