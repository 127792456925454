// REACT
import React, { useState } from 'react';

// YARN
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';

// SERVICES
import dropletLogo from 'assets/images/laissez-tomber.png';
import logoCover from 'assets/images/logo-cover-min.png';
import { StyledTooltip } from 'utils/Datagrid/CustomGenericDataGridUtils';

// INTERFACES
import { IParcelCulture } from 'components/generics/Interface/Api/Response/Exploitation/Parcels/IParcelCulture';
import { GridRowModel, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-pro';

export const CustomSimulationCell = (
  params: GridValidRowModel,
  editedSynthesisCellsRef: React.MutableRefObject<string[]>,
  setSelectIndex: React.Dispatch<React.SetStateAction<number | null>>
): React.JSX.Element => {
  const { isParcelDivision, cultures } = params?.value;

  const isSplittedCellEdited = (idx: number) =>
    editedSynthesisCellsRef.current.includes(`${params?.id.toString()}-${params?.field}-${idx}`);

  return cultures.map((culture: IParcelCulture, idx: number) => {
    const isCover = culture?.cover === 0 || culture?.cover === 1;
    return isParcelDivision ? (
      <Box
        className={`cell-box ${isParcelDivision ? 'cell-box-division' : ''} ${
          isSplittedCellEdited(idx) ? 'MuiCell--editing' : ''
        }`}
        onClick={() => {
          setSelectIndex(idx);
        }}
        key={idx}
      >
        <div className="cell-main-content content-with-surface">
          <div className="cell-main-content__infos">
            <div className="cell-main-content__infos__surface">
              <span>{Number(culture.surface.toFixed(2))}</span>
            </div>
            <div>{culture.name}</div>
          </div>
          <div className="cultures-icons-wrapper">
            {culture.irrigation > 0 && (
              <div className="culture-infos-icon">
                <img src={dropletLogo} alt="Logo irrigation" className="dropletLogo" />
              </div>
            )}
            {isCover && (
              <div className="culture-infos-icon">
                <StyledTooltip
                  title={`${
                    culture?.cover === 0
                      ? 'Autre couvert possible avant la culture '
                      : 'Couvert à base légumineuse avant la culture'
                  }`}
                  placement="top"
                >
                  <img
                    src={logoCover}
                    alt="Logo couvert"
                    className={`${
                      culture?.cover === 0
                        ? 'disabled-cover dropletLogo'
                        : 'dropletLogo not-disabled-cover'
                    }`}
                  />
                </StyledTooltip>
              </div>
            )}
          </div>
        </div>
        <EditIcon
          sx={{
            padding: '0px !important',
          }}
        />
      </Box>
    ) : (
      <Box
        key={idx}
        className={`cell-box ${isSplittedCellEdited(idx) ? 'MuiCell--editing' : ''}`}
        onClick={() => {
          setSelectIndex(idx);
        }}
      >
        <div className="cell-main-content">
          <div>{culture.name}</div>
          <div className="cultures-icons-wrapper">
            {culture.irrigation > 0 && (
              <div className="culture-infos-icon">
                <img src={dropletLogo} alt="Logo irrigation" className="dropletLogo" />
              </div>
            )}
            {isCover && (
              <div className="culture-infos-icon">
                <StyledTooltip
                  title={`${
                    culture?.cover === 0
                      ? 'Autre couvert possible avant la culture '
                      : 'Couvert à base légumineuse avant la culture'
                  }`}
                  placement="top"
                >
                  <img
                    src={logoCover}
                    alt="Logo couvert"
                    className={`${
                      culture?.cover === 0
                        ? 'disabled-cover dropletLogo'
                        : 'dropletLogo not-disabled-cover'
                    }`}
                  />
                </StyledTooltip>
              </div>
            )}
          </div>
        </div>
        <EditIcon sx={{ padding: '0px !important' }} />
      </Box>
    );
  });
};

type CustomEditSelectCellParams = {
  params: GridRowModel;
  culturesList: IParcelCulture[];
  selectIndex: number;
};

export const CustomEditSelectCell = ({
  params,
  culturesList,
  selectIndex,
}: CustomEditSelectCellParams): React.JSX.Element => {
  const { id, field, value } = params;
  const { cultures } = value;
  const [checked, setChecked] = useState(params.value?.cultures[selectIndex]?.cover === 1);
  const apiRef = useGridApiContext();
  const cover = params.value?.cultures[selectIndex]?.cover;
  const isCover = cover === 0 || cover === 1;

  const handleCultureChange = (e: SelectChangeEvent<string>) => {
    const updatedValueObject = structuredClone(params.value);

    const {
      target: { value },
    } = e;

    const newValue = {
      id: value,
      name: culturesList.find((culture) => culture.id === Number(value))?.name,
      surface: cultures[selectIndex]?.surface,
      irrigation: cultures[selectIndex]?.irrigation,
      isMain: cultures[selectIndex]?.isMain,
      cover: cultures[selectIndex]?.cover,
      cellId: `${id.toString()}-${field}-${selectIndex}`,
      isEdited: true,
    };

    updatedValueObject.cultures[selectIndex] = newValue;

    apiRef.current.setEditCellValue({ id, field, value: updatedValueObject });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const handleCoverChange = (isChecked: boolean) => {
    setChecked(isChecked);

    const newValue = {
      ...value,
      cultures: value.cultures.map((culture: IParcelCulture, index: number) => {
        if (index === selectIndex) {
          return {
            ...culture,
            cover: isChecked ? 1 : 0,
            cellId: `${id.toString()}-${field}-${selectIndex}`,
            isEdited: true,
          };
        }
        return culture;
      }),
    };

    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Box className="parcel-synthesis-select-wrapper">
      <Select
        defaultOpen={false}
        value={cultures[selectIndex]?.id}
        onChange={(e) => handleCultureChange(e)}
        className="parcel-synthesis-select"
        IconComponent={KeyboardArrowDownIcon}
      >
        {culturesList?.map((culture) => (
          <MenuItem key={culture.id} value={culture.id}>
            {culture.name}
          </MenuItem>
        ))}
      </Select>
      {cover !== -1 && (
        <div className="wrapper-cover">
          {isCover && (
            <Box className="wrapper-cover-icon" onClick={() => handleCoverChange(!checked)}>
              <img
                src={logoCover}
                alt="Logo couvert"
                className={`${cover === 0 ? 'disabled-cover' : ''}`}
              />
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};
