import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import useAuth from 'providers/Auth/useAuth';

import { getCooperative } from 'services/API/Cooperative';
import {
  getCulturesByExploitationId,
  getExploitationInformations,
} from 'services/API/Exploitation';

import ExploitationHome from 'components/pages/Exploitation/Home';
import ExploitationSimulation from 'components/pages/Exploitation/Simulation';
import ExploitationSimulationResult from 'components/pages/Exploitation/Simulation/Result';
import ExploitationComparatorForecast from 'components/pages/Exploitation/Comparator/Forecast';
import ExploitationComparatorResult from 'components/pages/Exploitation/Comparator/Result';
import Parcels from './Parcels';

import { ErrorSimulationResultFallback } from 'utils/ErrorFallback';
import { sendEvent } from 'utils/Event';

import 'assets/styles/modal.style.scss';
import 'assets/styles/section.style.scss';
import CooperativeCsvImport from '../Cooperative/CsvImport';
import CooperativeImport from '../Cooperative/Import';
import LoginWiuz from '../Cooperative/LoginWiuz';
import CooperativeExploitation from '../Cooperative/Exploitation';
import Result from '../Cooperative/Result';
import Geofolia from '../Cooperative/Geofolia';
import TelepacImport from './TelepacImport';
import { ArableContext, ExploitationContext, LoadingContext } from 'utils/context';
import ExploitationHeader from './header/exploitation.header';
import CulturesDataGridExploitation from './CulturesDataGridExploitation';
import { getRandomColor, emptyCulture } from 'utils/culturesColors';
import GeofoliaProcess from '../Cooperative/GeofoliaProcess';
import RotateEffectsDataGridExploitation from './RotateEffectsDataGridExploitation';
import GroundTypeEffectsDataGridExploitation from './GroundTypeEffectsDataGridExploitation';

const Exploitation = () => {
  const [secteurs, setSecteurs] = useState([]);
  const { user } = useAuth();
  const cooperativeId = localStorage.getItem('cooperative');
  const idGet = cooperativeId ? cooperativeId : user.cooperative.id;
  const { setFullScreen } = useContext(LoadingContext);
  const [arableSurface, setArableSurface] = useState(0);
  const value = {
    arableSurface,
    setArableSurface,
  };
  const { setForceLoader } = useContext(LoadingContext);

  const { exploitationContext, setExploitationContext, setCulturesColors } =
    useContext(ExploitationContext);

  const loadData = async () => {
    setForceLoader(true);
    if (!localStorage.getItem('exploitation')) {
      setFullScreen(true);
    }
    try {
      const response = await getCooperative(idGet);
      setSecteurs(response.data.data.sectors);
    } catch (err) {
      toast.error(err.data);
    } finally {
      setForceLoader(false);
    }
  };

  const setColorContext = (data) => {
    let getStructureColors = [];
    let userCulturesColors = data.map((c) => c.color).filter((c) => c && c !== null);
    data.forEach((culture) => {
      let randomColor = getRandomColor(userCulturesColors);
      userCulturesColors.push('#' + randomColor);
      getStructureColors.push({
        id: culture.id,
        name: culture.name,
        color: culture.color ? culture.color.replace('#', '') : randomColor,
        isArable: culture.isArable,
        irrigationWaterNeeded: culture.irrigationWaterNeeded,
      });
    });
    getStructureColors.push(emptyCulture);
    setCulturesColors(getStructureColors);
  };

  const loadDataExploitation = async (id) => {
    try {
      const results = await Promise.all([
        getExploitationInformations(id),
        getCulturesByExploitationId(id),
      ]);
      setExploitationContext(results[0].data.data);
      setArableSurface(results[0].data.data?.arableTotalSurface);

      if (results[1].data.data) {
        setColorContext(results[1].data.data);
      }

      return results[0];
    } catch (error) {
      toast.error(error.data);
    }
  };

  useEffect(() => {
    user.cooperative && loadData();
  }, [user]);

  // Life Hook Dismount
  useEffect(() => {
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  return (
    <div className="ExploitationContainer">
      <ArableContext.Provider value={value}>
        <ErrorBoundary FallbackComponent={ErrorSimulationResultFallback}>
          <ExploitationHeader />
          <Routes>
            <Route
              path="/"
              element={
                <ExploitationHome
                  secteurs={secteurs}
                  setExploitation={loadDataExploitation}
                  exploitation={exploitationContext}
                />
              }
            />
            <Route
              path="parcels"
              element={
                <Parcels
                  exploitation={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />
            <Route
              path="cultures"
              element={
                <CulturesDataGridExploitation
                  exploitation={exploitationContext}
                  setExploitation={loadDataExploitation}
                  structureId={idGet}
                  setColorContext={setColorContext}
                  user={user}
                />
              }
            />
            <Route
              path="groundTypesEffects"
              element={
                <GroundTypeEffectsDataGridExploitation
                  exploitation={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />
            <Route
              path="rotateEffects"
              element={
                <RotateEffectsDataGridExploitation
                  exploitation={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />
            <Route
              path="simulation"
              element={
                <ExploitationSimulation
                  exploitationSelected={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />

            <Route
              path="result"
              element={
                <ExploitationSimulationResult
                  exploitationSelected={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />
            <Route path="import" element={<CooperativeImport />} />
            <Route path="import/csvImport" element={<CooperativeCsvImport />} />
            <Route path="import/loginWiuz" element={<LoginWiuz />} />
            <Route path="import/exploitation" element={<CooperativeExploitation />} />
            <Route path="import/result" element={<Result />} />
            <Route path="import/geofolia" element={<Geofolia />} />
            <Route exact path="import/geofolia/process" element={<GeofoliaProcess />} />
            <Route exact path="import/telepac" element={<TelepacImport />} />

            <Route
              path="comparator"
              element={
                <ExploitationComparatorForecast
                  exploitationSelected={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />

            <Route
              path="comparator/result"
              element={
                <ExploitationComparatorResult
                  exploitationSelected={exploitationContext}
                  setExploitation={loadDataExploitation}
                />
              }
            />

            <Route element={<Navigate to="/" />} />
          </Routes>
        </ErrorBoundary>
      </ArableContext.Provider>
    </div>
  );
};

export default Exploitation;
